import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AESDecrypt } from '../../../encrypt-util';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Card, Form } from 'react-bootstrap';
import { updateRowColumns } from './UserDetailsSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { updateToggle, updateUserDetailsList } from './UserDetailsSlice';
import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons';
import {
  saveUserDetails,
  getGroupList,
  getAllUserDetailsByUser,
} from './action/UserDetails';
import { useTranslation } from 'react-i18next';
import { getUserProfile } from '../../common/actions/userDetails';

const User = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const userDetails = useSelector((state: RootState) => state.userList);
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const { userID } = useParams();
  const navigate = useNavigate();

  //User Id
  const [loginId, setLoginId] = useState('');

  //Title
  const [title, setTitle] = useState('');

  //User Name
  const [usrNm, setUsrNm] = useState('');

  //Contact Number
  const [contactNo, setContactNo] = useState('');

  //Contact Person
  const [contactPerson, setContactPerson] = useState('');

  const [groupList, setGroupList] = useState([]);

  const [status, setStatus] = useState('');

  const [disableFields, setDisableFields] = useState<any>(
    profileDetails?.partnerType === 'liner' ||
      (!!matchPath && profileDetails?.partnerType !== 'liner')
  );

  const [user, setUser] = useState<any>(
    AESDecrypt(userID?.toString()).toString() !== '0'
      ? {
          mstPartnerId: userDetails.data?.mstPartnerId,
          mstUsrId: null,
          loginId: '',
          usrNm: '',
          contactNo: '',
          title: 'Mr.',
          contactPerson: '',
          mstGrp: [],
          status: '',
          grpNm: '',
          countryShortCode: userDetails.data?.countryShortCode,
          countryName: userDetails.data.countryName,
          isRootUser: 'N',
          partnerNm: userDetails.data.partnerNm,
        }
      : userDetails?.data?.userData?.list?.find(
          (v: any) =>
            AESDecrypt(userID?.toString()).toString() === v.mstUsrId.toString()
        )
  );

  const [mstGrp, setMstGrp] = useState(
    user?.mstGrp.map((c: any) => {
      return {
        value: c?.mstGrpId,
        label: c?.usrGrpNm,
      };
    })
  );
  type FormValues = {
    loginId: string;
    title: string;
    usrNm: string;
    contactNo: string;
    contactPerson: string;
    mstGrp: [];
    status: string;
  };

  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    loginId: Yup.string().required(
      t('resource_3:isRequired', { field: 'User ID' })
    ),
    title: Yup.string().required(
      t('resource_3:isRequired', { field: 'Title' })
    ),
    usrNm: Yup.string().required(
      t('resource_3:isRequired', { field: 'User Name' })
    ),
    mstGrp: Yup.array().min(1, 'Pick at least 1 group'),
    status: Yup.string().required(
      t('resource_3:isRequired', { field: 'Status' })
    ),
  });

  //Setup
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (payload) => {
    try {
      const finalList =
        user.mstUsrId === null
          ? [
              {
                mstPartnerId: userDetails.data?.mstPartnerId,
                mstUsrId: null,
                loginId: loginId,
                usrNm: usrNm,
                contactNo: contactNo,
                title: title,
                contactPerson: contactPerson,
                grpNm: mstGrp?.map((u: any) => u.label).join(','),
                mstGrp: mstGrp.map((g: any) => {
                  return {
                    mstGrpId: g.value,
                    usrGrpNm: g.label,
                  };
                }),
                status: status,
                countryShortCode: userDetails.data?.countryShortCode,
                countryName: userDetails.data.countryName,
                isRootUser: 'N',
                partnerNm: userDetails.data.partnerNm,
              },
            ].concat(userDetails.data.userData.list.map((v: any) => v))
          : userDetails.data.userData.list.map((v: any) => {
              if (user.mstUsrId === v.mstUsrId) {
                return {
                  ...v,
                  loginId: loginId,
                  usrNm: usrNm,
                  contactNo: contactNo,
                  title: title,
                  contactPerson: contactPerson,
                  grpNm: mstGrp?.map((u: any) => u.label).join(','),
                  partnerNm: userDetails.data.partnerNm,
                  mstGrp: mstGrp.map((g: any) => {
                    return {
                      mstGrpId: g.value,
                      usrGrpNm: g.label,
                    };
                  }),
                  status: status,
                };
              } else {
                return v;
              }
            });

      dispatch(
        saveUserDetails({
          mstPartnerId: user.mstPartnerId,
          mstUsrId: user.mstUsrId,
          loginId: loginId.toLocaleLowerCase(),
          usrNm: usrNm,
          contactNo: contactNo,
          title: title,
          contactPerson: contactPerson,
          grpNm: mstGrp?.map((u: any) => u.label).join(','),
          mstGrp: mstGrp.map((g: any) => {
            return {
              mstGrpId: g.value,
              usrGrpNm: g.label,
            };
          }),
          status: status,
          countryShortCode: userDetails.data?.countryShortCode,
          countryName: userDetails.data.countryName,
          isRootUser: user.isRootUser,
          partnerNm: userDetails.data.partnerNm,
        })
      )
        .unwrap()
        .then((response: any) => {
          if (response === '') {
            toast.dark('Please Try Again');
            navigate('/users');
          } else {
            toast.dark(response.responseMsg.toString());
            dispatch(
              updateToggle({
                ...userDetails,
                toggle: true,
              })
            );
            dispatch(
              updateUserDetailsList({
                ...userDetails.data.userData,
                list: finalList,
              })
            );
            navigate('/users');
          }
        });
    } catch (err) {
      toast.dark('Please Try Again');
    }
  });

  useEffect(() => {
    if (matchPath('/users/details/:userID', location.pathname)) {
      // set form values as user changes
      setLoginId(user?.loginId);
      setValue('loginId', user?.loginId);
      setTitle(user?.title);
      setValue('title', user?.title);
      setUsrNm(user?.usrNm);
      setValue('usrNm', user?.usrNm);
      setContactNo(user?.contactNo);
      setValue('contactNo', user?.contactNo);
      setContactPerson(user?.contactPerson);
      setValue('contactPerson', user?.contactPerson);
      setMstGrp(
        user?.mstGrp.map((v: any) => {
          return { value: v?.mstGrpId, label: v?.usrGrpNm };
        })
      );
      setValue(
        'mstGrp',
        user?.mstGrp.map((v: any) => {
          return { value: v?.mstGrpId, label: v?.usrGrpNm };
        })
      );
      setStatus(user?.status);
      setValue('status', user?.status);
      dispatch(getGroupList({}))
        .unwrap()
        .then((response: any) => {
          // set group list to dropdown list
          setGroupList(
            response.map((v: any) => {
              return { label: v?.usrGrpNm, value: v?.mstGrpId };
            })
          );
        });
    }
  }, [user]);

  useEffect(() => {
    if (matchPath('/users/details/:userID', location.pathname)) {
      dispatch(
        updateRowColumns({
          mainTableClass: 'col-lg-2',
          outletClass: 'col-lg-10',
        })
      );
      if (AESDecrypt(userID?.toString()).toString() === '0') {
        //Set data for new user

        setUser({
          mstPartnerId: userDetails.data?.mstPartnerId,
          mstUsrId: null,
          loginId: '',
          usrNm: '',
          contactNo: '',
          title: 'Mr.',
          contactPerson: '',
          mstGrp: [],
          status: '',
          countryShortCode: userDetails.data?.countryShortCode,
          isRootUser: 'N',
          partnerNm: userDetails.data.partnerNm,
        });
        setDisableFields(false);
      } else {
        //get selected user
        setDisableFields(true);
        dispatch(getUserProfile())
          .unwrap()
          .then((response: any) => {
            if (
              userDetails.data.userData.list.map((v: any) => v).length === 0
            ) {
              dispatch(
                getAllUserDetailsByUser({
                  partnerId: response?.data?.partnerId,
                  usrId: response?.data?.userId,
                })
              )
                .unwrap()
                .then((response: any) => {
                  setUser(
                    response.userData.list.find(
                      (v: any) =>
                        AESDecrypt(userID?.toString()).toString() ===
                        v.mstUsrId.toString()
                    )
                  );
                });
            } else {
              setUser(
                userDetails.data.userData.list.find(
                  (v: any) =>
                    AESDecrypt(userID?.toString()).toString() ===
                    v.mstUsrId.toString()
                )
              );
            }
          });
      }
      // convert group list to dropdown list
      setMstGrp(
        user?.mstGrp?.map((v: any) => {
          return {
            value: v?.mstGrpId,
            label: v?.usrGrpNm,
          };
        })
      );
    } else if (matchPath('/users', location.pathname)) {
      dispatch(
        updateRowColumns({
          mainTableClass: 'col-lg-12',
          outletClass: 'd-none',
        })
      );

      navigate('/users');
    }
  }, [location.pathname]);

  return (
    <div className=' h-100'>
      <Form onSubmit={onSubmit} autoComplete='off'>
        <div>
          <div className='row'>
            <div className='col-lg-6 '>
              <Form.Group controlId='user.loginId'>
                <Form.Label className='fs--1 '>
                  <span className='mandatory'>*</span>
                  {'User Email Id'}
                </Form.Label>
                <Form.Control
                  {...register('loginId')}
                  className={`fs--1 form-control textTransform ${
                    errors.loginId ? 'is-invalid' : ''
                  }`}
                  type='text'
                  onChange={(e) => {
                    setLoginId(e.target.value.replaceAll(' ',''));
                    //_onAccountAliasChange(e.target.value);
                    setValue('loginId', e.target.value.replaceAll(' ',''));
                  }}
                  onBlur={(e) => {
                    if (loginId !== '') {
                      setValue('loginId', e.target.value);
                    }
                  }}
                  size='sm'
                  maxLength={200}
                  value={loginId}
                  disabled={AESDecrypt(userID?.toString()).toString() !== '0' }
                />
                <div className='invalid-feedback'>
                  {errors.loginId?.message?.toString()}
                </div>
              </Form.Group>
            </div>
            <div className='col-lg-6 '>
              <Form.Group controlId='user.usrNm'>
                <Form.Label className='fs--1 '>
                  <span className='mandatory'>*</span>
                  {'User Name'}
                </Form.Label>
                {/*<div className='col-lg-4 mb-2'>
                                    <Form.Select
                                        {...register('title')}
                                        className={`fs--1 form-control ${
                                        errors.title ? 'is-invalid' : ''
                                        }`}
                                        value={title}
                                        onChange={(e) => { 
                                            setTitle(e.target.value);
                                            setValue('title', e.target.value);
                                            }}>
                                        <option value='Mr.'  >Mr</option>
                                        <option value='Mrs.' >Mrs</option>
                                    </Form.Select>
                                        </div>
                                <div className='col-lg-8 '>*/}
                <Form.Control
                  {...register('usrNm')}
                  className={`fs--1 form-control ${
                    errors.usrNm ? 'is-invalid' : ''
                  }`}
                  type='text'
                  maxLength={200}
                  onChange={(e) => {
                    setUsrNm(e.target.value);
                    setValue('usrNm', e.target.value);
                    //_onAccountAliasChange(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (usrNm !== '') {
                      setValue('usrNm', e.target.value);
                    }
                  }}
                  size='sm'
                  value={usrNm}
                />
                {/*</div>*/}

                <div className='invalid-feedback'>
                  {errors.title?.message?.toString()}
                  {errors.usrNm?.message?.toString()}
                </div>
              </Form.Group>
            </div>
            {/*For now hidding contact number and contact person <div className='col-lg-6 mb-3'>
                            <Form.Group controlId='user.contactNo'>
                                <Form.Label className='fs--1 '>
                                    <span className='mandatory'>*</span>
                                    {'Contact Number'}
                                </Form.Label>
                                <Form.Control
                                    {...register('contactNo')}
                                    className={`fs--1 form-control ${
                                        errors.contactNo ? 'is-invalid' : ''
                                    }`}
                                    type='text'
                                    onChange={(e) => {
                                        setContactNo(e.target.value);
                                        //_onAccountAliasChange(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        if (contactNo !== '') {
                                        setValue('contactNo', e.target.value);
                                        }
                                    }}
                                    size='sm'
                                    value={contactNo}        
                                />
                                <div className='invalid-feedback'>
                                    {errors.contactNo?.message?.toString()}
                                </div>
                            </Form.Group>       
                        </div>
                        <div className='col-lg-6 mb-3'>
                            <Form.Group controlId='user.contactPerson'>
                                <Form.Label className='fs--1 '>
                                    <span className='mandatory'>*</span>
                                    {'Contact Person'}
                                </Form.Label>
                                <Form.Control
                                    {...register('contactPerson')}
                                    className={`fs--1 form-control ${
                                        errors.contactPerson ? 'is-invalid' : ''
                                    }`}
                                    type='text'
                                    onChange={(e) => {
                                        setContactPerson(e.target.value);
                                        //_onAccountAliasChange(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        if (contactPerson !== '') {
                                        setValue('contactPerson', e.target.value);
                                        }
                                    }}
                                    size='sm'
                                    value={contactPerson}        
                                />
                                <div className='invalid-feedback'>
                                    {errors.contactPerson?.message?.toString()}
                                </div>
                            </Form.Group>       
                                </div>*/}
            <div className='col-lg-6 ' style={{ zIndex: 999 }}>
              <Form.Label className='fs--1'>
                <span className='mandatory'>*</span>
                {'Group'}
              </Form.Label>
              <Select
                {...register('mstGrp')}
                onChange={(u: any) => {
                  setMstGrp(u.map((v: any) => v));
                  setValue(
                    'mstGrp',
                    u.map((v: any) => v)
                  );
                }}
                options={groupList}
                className={`fs--1  ${
                  errors.mstGrp?.toString ? 'is-invalid' : ''
                }`}
                noOptionsMessage={() => 'No group'}
                isMulti={true}
                autoFocus={true}
                isSearchable={true}
                value={mstGrp}
              />

              <div className='invalid-feedback'>
                {errors.mstGrp?.message?.toString()}
              </div>
            </div>
            <div className='col-lg-6 '>
              <Form.Group controlId='user.status'>
                <Form.Label className='fs--1'>
                  <span className='mandatory'>*</span>
                  {'Status'}
                </Form.Label>
                <Form.Select
                  {...register('status')}
                  className={`fs--1 form-control ${
                    errors.status ? 'is-invalid' : ''
                  }`}
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value=''>Select</option>
                  <option value='1'>Active</option>
                  <option value='2'>Inactive</option>
                </Form.Select>
                <div className='invalid-feedback'>
                  {errors.status?.message?.toString()}
                </div>
              </Form.Group>
            </div>
            <div className='d-flex justify-content-center align-items-center mt-4'>
              <Button
                type='submit'
                className='me-2'
                variant='falcon-danger'
                size='sm'
              >
                <FontAwesomeIcon icon={faFloppyDisk} className='me-2' />
                {'Save'}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default User;
