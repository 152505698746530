import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Form,
  FormControl,
  FormLabel,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import {
  faTimes,
  faSave,
  faRedo,
  faLocation,
  faInfoCircle,
  faBackwardStep,
  faCheck,
  faCancel,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toggleAddAgent, toggleNominationReq } from '../slices/dashboardSlice';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ChangeEvent, ChangeEventHandler, useState } from 'react';
import { AppDispatch, RootState } from '../../../store';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { updateSelectedTab } from '../../nominationReq/slices/nominationReq';
import { toggleDocumentHistoryColumnShort } from '../../blDetails/slices/documentHistorySlice';
import { enableNominateAgent } from '../../standingInstruction/slices/standingInstructionTable';
import FileUpload from '../../blPayment/FileUpload';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAsyncDebounce } from 'react-table';
import { fetchAddressSuggestions2 } from '../../dashboard/actions/instruction';
import { getCountryList } from '../../registration/actions/Registration';
import { updateSelectedAddressResult } from '../../blRequestInstruction/components/instructionSlice/CarrierHaulageSlice';
import { InstructionList } from '../../dashboard/types';
import React, { useEffect } from 'react';
import {
  addAgentNnominate,
  getAgentNnominate,
  getCodeList,
  addMultiAgentNnominate,
} from '../../blRequestInstruction/components/actions/Instruction';
import { getInstructionList } from '../../dashboard/actions/instruction';
import { resolve } from 'path';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCompanyDetailsForAgentDelegation } from '../actions/instruction';
import { Root } from 'react-dom/client';
import {
  clearFields,
  toggleDisableFields,
  toggleGrantFrtInvAccess,
  toggleBlAccess,
  toggleTimePendingInvoiceAccess,
  updateAgentAccessList,
} from '../../blRequestInstruction/components/instructionSlice/AgentNominationSlice';
import { AESDecrypt } from '../../../../encrypt-util';
import SoftBadge from '../../../../components/common/SoftBadge';
import {
  faArrowAltCircleLeft,
  faEdit,
} from '@fortawesome/free-regular-svg-icons';
import InputMask from 'react-input-mask';
import AgentDelegationTNC from './agentDelegationTnc';
import { commonApproveReject } from '../../dashboard/actions/instruction';
import { IDataTableColumn } from '../../../common/types/dataTable';
import DataTable from '../../../common/components/DataTable';
import { updateSelectedTableData } from '../slices/dashboardSlice';
import { updateDashbaordRefresh, updateSortStateDataTable } from '../../../common/components/DataTable/slices/DataTable';
import { useBackNavigation } from '../../../../hooks/BackNavigation';
import useBlCountry from '../../../../hooks/useBlCountry';

const NominateAgent = () => {
  const data = useSelector((state: RootState) => state.agentDelegation.data);
  //const blList = useSelector((state: RootState) => state.dashboard.data);

  const invTypeList = useSelector(
    (state: RootState) => state.agentDelegation.invTypeList
  );

  const agentAccessList = useSelector(
    (state: RootState) => state.agentDelegation.agentAccessList
  );

  let selectedTab = useSelector(
    (state: RootState) => state.nominationReq.selectedTab
  );

  const countryOptions = useSelector(
    (state: RootState) => state.registration.countryOptions
  );

  const blPartyList = useSelector(
    (state: RootState) => state.oblDetails.data.partyList
  );

  const onBack = useBackNavigation()
  // const dataCountry = useSelector(
  //   (state: RootState) => state.userProfile.linerDataCountry
  // );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || null;

  const selectedRouter = useSelector(
    (state: RootState) => state.documentHistory.selectedRouter
  );

  const testData = useSelector(
    (state: RootState) => state.agentDelegation.data
  );

  /*  const selectedRouter = useSelector(
    (state: RootState) => state.documentHistory.selectedRouter
  ); */

  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();
  const { blNum, encrypt, encryptdoc } = useParams();
  const decrptdoc = AESDecrypt(encryptdoc);
  const decrptins = AESDecrypt(encrypt);
  const docType = decrptins;
  const docId = location.pathname.split('/')[4];
  const checkBLType = location.pathname.includes('multiBl')
    ? location.pathname.includes('multiBl')
    : false;
  const navigate = useNavigate();

  const [address, setAddress] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [partyName, setPartyName] = useState(profileDetails?.partnerName || '');
  const [email, setEmail] = useState('');
  const [partyEmail, setPartyEmail] = useState('');
  const [loiUpload, setLoiUpload] = useState('');
  const [byteArr, setByteArr] = useState('');
  const [fileName, setFileName] = useState('');
  const [agentPartnerId, setAgentPartnerId] = useState('');
  const [allowFreightInvoice, setAllowFreightInvoice] = useState('');
  const [allowBlAccess, setAllowBlAccess] = useState('');
  const [allowTimePendingInvoice, setAllowTimePendingInvoice] = useState('');
  const [agentNominationId, setAgentNominationId] = useState(0);
  const [status, setStatus] = useState('');
  const [taxIdFormat, setTaxIdFormat] = useState('');
  const [requestType, setRequestType] = useState('');

  //all instruction List
  const instructionList = useSelector(
    (state: RootState) => state.documentHistory.data
  );

  const [state, setState] = useState<any>({
    taxId: '',
    companyName: '',
    partyName: '',
    emailId: '',
    partyEmail: '',
    fileName: '',
    address: '',
    agentPartnerId: '',
    allowFreightInvoice: '',
    agentNominationId: '',
    status: '',
    allowTimePendingInvoice: '',
    allowBlAccess: '',
    customerRemarks: '',
    rejectRemark: '',
  });

  const disabledPath = [
    { path: '/instruction/:blNum/:encrypt/:encryptdoc' },
    {
      path: '/bl/:blNum/:encrypt/:encryptdoc',
      condition: location.pathname.split('/')[3] !== 'instruction'
    },
    {
      path: 'instruction/bl/:blNum/:encrypt/:encryptdoc',
      condition: location.pathname.split('/')[4] !== 'instruction'
    },
    {
      path: 'payment/bl/:blNum/:encrypt/:encryptdoc',
      condition: location.pathname.split('/')[4] !== 'instruction'
    },
  ]
    .some(({ path, condition }: { path: string, condition?: boolean }) => {
      return (condition ?? true) && matchPath(
        { path, caseSensitive: true, end: true }, location.pathname)
    })

  const blEventId_docInsDashboard:any = useSelector(
    (state: any) => state.dashboard.instructionDashboardList.result.filter((Item:any)=> Item.bl_id == AESDecrypt(blNum) )[0]?.bl_event_id
  );

  const [blCountry, setBlCountry] = useBlCountry(
    disabledPath || location.pathname.includes('multiBl')?"view":"new",
    [813, 814, 815, "813", "814", "815"].includes(decrptins)?"export":"import",
  );

  useEffect(()=>{
    if(location.pathname.includes('multiBl')){
      setBlCountry(dataCountry)
    }
  }, [location.pathname])

  const selectedLinerId =
    useSelector((state: RootState) => state.navbarTop.linerId) ||
    [
      { path: '/instruction/:blNum/:encrypt/:encryptdoc' },
      { path: '/bl/:blNum/:encrypt/:encryptdoc' },
      { path: 'instruction/bl/:blNum/:encrypt/:encryptdoc' },
      { path: 'payment/bl/:blNum/:encrypt/:encryptdoc' }
    ]
      .some(({ path, condition }: { path: string, condition?: boolean }) => {
        return (condition ?? true) && matchPath(
          { path, caseSensitive: true, end: true }, location.pathname)
      })

  useEffect(() => {
    dispatch(getCountryList(1))
      .unwrap()
      .then((response: any) => {
        response.map((option: any) => {
          if (option.countryShortCode === profileDetails.partnerCountry) {
            setTaxIdFormat(option.taxIdFormat);
          }
        });
      });
  }, []);

  useEffect(() => {
    if (decrptdoc != '' && selectedLinerId != '') {
      dispatch(
        getAgentNnominate({
          decrptdoc,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails.partnerId
              : selectedLinerId,
        })
      ).unwrap().then((data)=>{
        setBlCountry(data.list.country)
      });
    }
    dispatch(
      toggleDisableFields(
        profileDetails?.partnerType === 'liner' ||
        (!!disabledPath && profileDetails?.partnerType !== 'liner')
      )
    );
  }, [decrptdoc, selectedLinerId]);

  const isGrantFrtInvAccess = useSelector(
    (state: RootState) => state.agentDelegation.isGrantFrtInvAccess
  );

  const isBlAccess = useSelector(
    (state: RootState) => state.agentDelegation.isBlAccess
  );

  const isTimePendingInvoiceAccess = useSelector(
    (state: RootState) => state.agentDelegation.isTimePendingInvoiceAccess
  );
  // useEffect(() => {
  //   isGrantFrtInvAccess? setAllowFreightInvoice('Y') : setAllowFreightInvoice('N');
  // })

  const [termsAccepted, setTermsAccepted] = useState(false);

  const onToggleGrantFrtInvAccess = () => {
    dispatch(toggleGrantFrtInvAccess(!isGrantFrtInvAccess));
  };
  const onToggleBlAccess = () => {
    dispatch(toggleBlAccess(!isBlAccess));
  };
  const onToggleTimePendingInvoiceAccess = () => {
    dispatch(toggleTimePendingInvoiceAccess(!isTimePendingInvoiceAccess));
  };

  const selectedAddressResult = useSelector(
    (state: RootState) => state.carrierHaulage.selectedAddressResult
  );

  const isAddressSuggesstionLoading = useSelector(
    (state: RootState) => state.carrierHaulage.isAddressSuggesstionsLoading
  );

  const registrationAddressSuggesstion = useSelector(
    (state: RootState) => state.carrierHaulage.registrationAddressSuggesstion
  );

  const addr = useSelector((state: RootState) => state.agentDelegation.address);
  const compName = useSelector(
    (state: RootState) => state.agentDelegation.companyName
  );
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  const emailId = useSelector(
    (state: RootState) => state.agentDelegation.emailId
  );
  const partnerId = useSelector(
    (state: RootState) => state.agentDelegation.agentPartnerId
  );
  const selectedDataNominatedAgent = data?.list as any;

  function extractValuesFromUrl(url: any) {
    const params = new URLSearchParams(url);
    const sortParam = params.get('sort');

    if (sortParam) {
      const arr: any = sortParam.split('%2C');
      return arr;
    } else {
      return [];
    }
  }
  const setInitialValue = () => {
    if (blNum) {
      if (disabledPath) {
        if (disableFields) setValue('taxId', '')
        //  const selectedDataNominatedAgent = data?.list as any;

        if (!matchPathInstruction() && selectedDataNominatedAgent?.agentTaxId) {
          setValue('taxId', selectedDataNominatedAgent?.agentTaxId);
        }
        if (selectedDataNominatedAgent?.agentTaxId || watch('taxId')) {
          setValue('taxId', selectedDataNominatedAgent?.agentTaxId || watch('taxId'));
        }
        // setTaxId(
        //   !matchPathInstruction()
        //     ? selectedDataNominatedAgent?.agentTaxId || ''
        //     : ''
        // );
        if (
          !matchPathInstruction() &&
          selectedDataNominatedAgent?.agentPartnerName
        ) {
          setCompanyName(selectedDataNominatedAgent?.agentPartnerName);
        }
        if (selectedDataNominatedAgent?.agentPartnerName || companyName) {
          setValue(
            'companyName',
            selectedDataNominatedAgent?.agentPartnerName || companyName
          );
        }
        // setCompanyName(
        //   !matchPathInstruction()
        //     ? selectedDataNominatedAgent?.agentPartnerName || ''
        //     : ''
        // );
        if (
          !matchPathInstruction() &&
          selectedDataNominatedAgent?.agentEmailId
        ) {
          setEmail(selectedDataNominatedAgent?.agentEmailId);
        }
        if (selectedDataNominatedAgent?.agentEmailId || email) {
          setValue('email', selectedDataNominatedAgent?.agentEmailId || email);
        }

        // setEmail(
        //   !matchPathInstruction()
        //     ? selectedDataNominatedAgent?.agentEmailId || ''
        //     : ''
        // );
        if (!matchPathInstruction() && selectedDataNominatedAgent?.fileName) {
          setFileName(selectedDataNominatedAgent?.fileName);
        }
        // setFileName(
        //   !matchPathInstruction()
        //     ? selectedDataNominatedAgent?.fileName || ''
        //     : ''
        // );
        if (!matchPathInstruction() && selectedDataNominatedAgent?.address) {
          setAddress(selectedDataNominatedAgent?.address);
        }
        if (selectedDataNominatedAgent?.address || address) {
          setValue('address', selectedDataNominatedAgent?.address || address);
        }
        // setAddress(
        //   !matchPathInstruction()
        //     ? selectedDataNominatedAgent?.address || ''
        //     : ''
        // );
        if (
          !matchPathInstruction() &&
          selectedDataNominatedAgent?.allowFreightInvoice
        ) {
          setAllowFreightInvoice(
            selectedDataNominatedAgent?.allowFreightInvoice
          );
        }
        if (
          selectedDataNominatedAgent?.allowFreightInvoice ||
          allowFreightInvoice
        ) {
          setValue(
            'allowFreightInvoice',
            selectedDataNominatedAgent?.allowFreightInvoice ||
            allowFreightInvoice
          );
        }
        if (
          !matchPathInstruction() &&
          selectedDataNominatedAgent?.timePendingInvoice
        ) {
          setAllowTimePendingInvoice(
            selectedDataNominatedAgent?.timePendingInvoice
          );
        }
        if (
          selectedDataNominatedAgent?.timePendingInvoice ||
          allowTimePendingInvoice
        ) {
          setValue(
            'allowTimePendingInvoice',
            selectedDataNominatedAgent?.timePendingInvoice ||
            allowTimePendingInvoice
          );
        }

        if (!matchPathInstruction() && selectedDataNominatedAgent?.blAccess) {
          setAllowBlAccess(selectedDataNominatedAgent?.blAccess);
        }
        if (selectedDataNominatedAgent?.blAccess || allowBlAccess) {
          setValue(
            'allowBlAccess',
            selectedDataNominatedAgent?.blAccess || allowBlAccess
          );
        }
        // setAllowFreightInvoice(
        //   !matchPathInstruction()
        //     ? selectedDataNominatedAgent?.allowFreightInvoice || ''
        //     : ''
        // );
        if (
          !matchPathInstruction() &&
          selectedDataNominatedAgent?.agentNominationId
        ) {
          setAgentNominationId(selectedDataNominatedAgent?.agentNominationId);
        }
        if (
          selectedDataNominatedAgent?.agentNominationId ||
          agentNominationId
        ) {
          setValue(
            'agentNominationId',
            selectedDataNominatedAgent?.agentNominationId || agentNominationId
          );
        }
        // setAgentNominationId(
        //   !matchPathInstruction()
        //     ? selectedDataNominatedAgent?.agentNominationId || ''
        //     : ''
        // );
        if (
          !matchPathInstruction() &&
          selectedDataNominatedAgent?.agentPartnerId
        ) {
          setAgentPartnerId(
            partnerId ? partnerId : selectedDataNominatedAgent?.agentPartnerId
          );
        }
        if (
          selectedDataNominatedAgent?.agentPartnerId ||
          agentPartnerId ||
          partnerId
        ) {
          setValue(
            'agentPartnerId',
            partnerId
              ? partnerId
              : selectedDataNominatedAgent?.agentPartnerId || agentPartnerId
          );
        }
        if (selectedDataNominatedAgent?.requestType) {
          setRequestType(selectedDataNominatedAgent?.requestType);
        }
        // setAgentPartnerId(
        //   !matchPathInstruction()
        //     ? selectedDataNominatedAgent?.agentPartnerId || ''
        //     : ''
        // );

        setState({
          ...state,
          customerRemarks: selectedDataNominatedAgent?.customerRemarks,
        });

        if (!matchPathInstruction() && selectedDataNominatedAgent?.status) {
          setStatus(selectedDataNominatedAgent?.status);
        }
        setValue('status', selectedDataNominatedAgent?.status);

        if (!matchPathInstruction() && selectedDataNominatedAgent?.partName) {
          setPartyName(selectedDataNominatedAgent?.partName);
        }
        if (selectedDataNominatedAgent?.partName || partyName) {
          setValue(
            'partyName',
            selectedDataNominatedAgent?.partName || partyName
          );
        }
        if (
          !matchPathInstruction() &&
          selectedDataNominatedAgent?.agentEmailId
        ) {
          setPartyEmail(selectedDataNominatedAgent?.partyEmail);
        }
        if (selectedDataNominatedAgent?.partyEmail || partyEmail) {
          setValue(
            'partyEmail',
            selectedDataNominatedAgent?.partyEmail || partyEmail
          );
        }

        if (
          !matchPathInstruction() &&
          selectedDataNominatedAgent?.agentAccessList
        ) {
          dispatch(
            updateAgentAccessList(
              selectedDataNominatedAgent?.agentAccessList?.map((v: any) => {
                return {
                  agentAccessId: v?.agentAccessId || null,
                  agentNominationId: v?.agentNominationId || null,
                  type: v?.type,
                  allowAccess: v?.allowAccess,
                };
              })
            )
          );
        }
      }
    }
  };

  useEffect(() => {
    setInitialValue();
  }, [location.pathname, data]);
  useEffect(() => {
    dispatch(
      toggleGrantFrtInvAccess(allowFreightInvoice == 'Y' ? true : false)
    );
  }, [allowFreightInvoice]);
  useEffect(() => {
    dispatch(toggleBlAccess(allowBlAccess == 'Y' ? true : false));
  }, [allowBlAccess]);
  useEffect(() => {
    dispatch(
      toggleTimePendingInvoiceAccess(
        allowTimePendingInvoice == 'Y' ? true : false
      )
    );
  }, [allowTimePendingInvoice]);
  const resetForm = () => {
    setValue('taxId', '');
    setAddress('');
    setCompanyName('');
    setEmail('');
    setPartyName('');
    setPartyEmail('');
    setLoiUpload('');
  };
  const formReset = () => {
    setState({
      //  blId: AESDecrypt(blNum),

      taxId: '',
      companyName: '',
      partyName: '',
      emailId: '',
      partyEmail: '',
      fileName: '',
      address: '',
      agentPartnerId: '',
      allowFreightInvoice: '',
      agentNominationId: '',
      status: '',
      allowTimePendingInvoice: '',
      allowBlAccess: '',
      customerRemarks: '',
      rejectRemark: '',
    });
  };
  useEffect(() => {
    if (blNum) {
      const selectedDataNominatedAgent = data?.list?.find?.(
        (i: any) => i?.blId == AESDecrypt(blNum)
      );
      const selectedDataNominatedAgent2 = data?.list as any;

      setState({
        blId: AESDecrypt(blNum),

        taxId: selectedDataNominatedAgent?.taxId,
        companyName: selectedDataNominatedAgent?.companyName,
        emailId: selectedDataNominatedAgent?.emailId,
        fileName: selectedDataNominatedAgent?.fileName,
        address: selectedDataNominatedAgent?.address,
        agentPartnerId: selectedDataNominatedAgent?.agentPartnerId,
        allowFreightInvoice: selectedDataNominatedAgent?.allowFreightInvoice,
        allowBlAccess: selectedDataNominatedAgent?.blAccess,
        allowTimePendingInvoice: selectedDataNominatedAgent?.timePendingInvoice,
        agentNominationId: selectedDataNominatedAgent?.agentNominationId,
        status: selectedDataNominatedAgent?.status,
        customerRemarks: selectedDataNominatedAgent2?.customerRemarks || '',
        //rejectRemark: selectedDataNominatedAgent2?.rejectRemark || '',
        rejectRemark: selectedDataNominatedAgent2?.linerRemarks || '',
      });
    }
  }, [location.pathname, data, blNum]);

  useEffect(() => {
    if(blCountry && linerId)
    dispatch(
      getCodeList({
        codeType: 'INV_TYPE',
        linerId: linerId,
        partnerCountry: blCountry
      })
    );
  }, [linerId, blCountry]);

  useEffect(() => {
    //for new instruction set type
    if (invTypeList.length > 0) {
      if (location.pathname.split('/')[3] === 'instruction' || location.pathname.split('/')[4] === 'instruction') {
        dispatch(
          updateAgentAccessList(
            invTypeList.map((v: any) => {
              return {
                agentAccessId: null,
                agentNominationId: null,
                type: v?.value,
                allowAccess: 'Y',
              };
            })
          )
        );
      } else {
        dispatch(
          updateAgentAccessList(
            selectedDataNominatedAgent?.agentAccessList?.map((v: any) => {
              return {
                agentAccessId: v?.agentAccessId || null,
                agentNominationId: v?.agentNominationId || null,
                type: v?.type,
                allowAccess: v?.allowAccess,
              };
            })
          )
        );
      }
    }
  }, [invTypeList]);

  const matchPathInstruction = () =>
    matchPath(
      {
        path: '/bl/:blNum/instruction/:encrypt',
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: 'instruction/bl/:blNum/instruction/:encrypt',
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: 'payment/bl/:blNum/instruction/:encrypt',
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: '/bl/:blNum/instruction/:encrypt',
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: 'instruction/bl/:blNum/instruction/:encrypt',
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: 'payment/bl/:blNum/instruction/:encrypt',
        caseSensitive: true,
        end: true,
      },
      location.pathname
    );
  const disableFields = useSelector(
    (state: RootState) => state.agentDelegation.disableFields
  );

  useEffect(() => {
    dispatch(
      toggleDisableFields(
        profileDetails?.partnerType === 'liner' ||
        (!!disabledPath && profileDetails?.partnerType !== 'liner')
      )
    );
    // setDisableFields(
    //   profileDetails?.partnerType === 'liner' ||
    //   (!!matchPth && profileDetails?.partnerType !== 'liner'));
  }, []);
  const _onCloseForm = () => {
    onBack()
    if (selectedRouter === 'instruction'
      && window.location.search) {
      const paramVal = extractValuesFromUrl(window.location.search);
      const splitedval = paramVal[0].split(',');
      setTimeout(() => {
        dispatch(
          updateSortStateDataTable({
            key: splitedval[0],
            value: splitedval[1],
          })
        );
      }, 100);

    } else if (selectedRouter === 'payment'
      && window.location.search) {
      const paramVal = extractValuesFromUrl(window.location.search);
      const splitedval = paramVal[0].split(',');
      setTimeout(() => {
        dispatch(
          updateSortStateDataTable({
            key: splitedval[0],
            value: splitedval[1],
          })
        );
      }, 100);
    } else if (
      decrptins == '805' ||
      decrptins == '811' ||
      decrptins == '812' ||
      decrptins == '813' ||
      decrptins == '814' ||
      decrptins == '815'
    ) {
      if (window.location.search) {
        const paramVal = extractValuesFromUrl(window.location.search);
        const splitedval = paramVal[0].split(',');
        setTimeout(() => {
          dispatch(
            updateSortStateDataTable({
              key: splitedval[0],
              value: splitedval[1],
            })
          );
        }, 100);
      }
    } else {
      dispatch(enableNominateAgent(false));
    }

    dispatch(toggleDocumentHistoryColumnShort(false));
  };

  type FormValues = {
    taxId: string;
    address: string;
    companyName: string;
    partyName: string;
    email: string;
    partyEmail: string;
    contactNo: string;
    loiUpload: any;
    byteArr: string;
    agentPartnerId: any;
    agentNominationId: any;
    allowFreightInvoice: any;
    status: string;
    allowTimePendingInvoice: string;
    allowBlAccess: string;
  };

  const validationSchema = Yup.object().shape({
    taxId: Yup.string()
      .required(t('resource_3:isRequired', { field: t('resource_2:taxId') }))
      .when('country', {
        is: 'USA',
        then: Yup.string().matches(
          /^\d\d-\d{7}$/,
          'Invalid input format. It should match the pattern "DD-#######"'
        ),
      }),
    address: Yup.string().required(
      t('resource_3:isRequired', { field: t('resource_1:address') })
    ),
    companyName: Yup.string().required(
      t('resource_3:isRequired', { field: t('resource_2:companyName') })
    ),
    email: Yup.string()
      .email(t('resource_3:isInvalid', { field: t('resource_1:email') }))
      .required(t('resource_3:isRequired', { field: t('resource_1:email') })),

    //contactNo: Yup.string().required(' Contact No is required'),
    //loiUpload: Yup.string().required('Upload LOI '),
    //byteArr: Yup.string().required('Upload LOI'),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const agentNominationAllInstructionIds: any[] = [
    '811',
    '812',
    '813',
    '815',
    811,
    812,
    813,
    815,
  ];
  const onSubmit = handleSubmit(
    async (payload: any) => {
      try {
        dispatch(
          addMultiAgentNnominate({
            ...payload,
            requestedPartnerId: selectedDataNominatedAgent?.requestedPartnerId
              ? selectedDataNominatedAgent?.requestedPartnerId
              : profileDetails?.partnerId,
            blId: AESDecrypt(blNum).toString(),
            byteArr: byteArr,
            country: blCountry,
            linerId: linerId,
            fileName: fileName,
            timePendingInvoice: isTimePendingInvoiceAccess ? 'Y' : 'N',
            blAccess: isBlAccess ? 'Y' : 'N',
            allowFreightInvoice: allowFreightInvoice ? 'Y' : 'N',
            agentType: 'consumer',
            customerRemarks: state?.customerRemarks || null,
            accessList: agentAccessList.map((v: any) => {
              return {
                ...v,
                requestAccess: v?.allowAccess || null,
              };
            }),
            status: 'Completed',
            requestType:
              agentNominationId !== 0 && selectedDataNominatedAgent?.requestType
                ? selectedDataNominatedAgent?.requestType
                : decrptins
                  ? decrptins
                  : '805',
            partyName: partyName,
            partyEmail: partyEmail,
            isMultiReq: true,
          })
        )
          .unwrap()
          .then((v: any) => {
            if (v?.data) {
              if (v?.data.length > 0) {
                if (
                  v.data
                    .map((x: any) => x.message !== 'Data Saved Successfully')
                    .includes(true)
                ) {
                  v.data.map((x: any) => {
                    //failed
                    toast.dark(x.message);
                  });
                } else {
                  //success
                  toast.dark(t('resource_2:successfullyCreated'));
                  dispatch(updateSelectedTableData([]));
                }
              }
            } else {
              //exception
              toast.dark('Please try again !');
            }
          });
        //toast.dark(t('resource_2:successfullyCreated'));
        reset();

        if (location.pathname.split('/')[1] === 'multiBl') {
          onBack()
        } else if (location.pathname.split('/')[1] === 'instruction') {
          setTimeout(() => {
            dispatch(
              getInstructionList({
                blNum: AESDecrypt(blNum),
                country: dataCountry
              })
            );
          }, 2000);
          onBack()
        } else {
          setTimeout(() => {
            dispatch(
              getInstructionList({
                blNum: AESDecrypt(blNum),
                country: dataCountry
              })
            );
          }, 2000);
          onBack()
        }
      } catch (err: any) {
        console.error(err.message);
      }
    },
    (error: any) => {
      console.error('Handle submit error: ', error);
    }
  );

  const onApproveReject = (status: string, type: string) => {
    if (status && type) {
      if (
        status === 'R' &&
        (state?.rejectRemark === '' || state?.rejectRemark == null)
      ) {
        toast.dark(t('resource_message:remarksMandatory'));
      } else {
        dispatch(
          commonApproveReject({
            instructionId: decrptdoc ? parseInt(decrptdoc) || null : null,
            instructionType: type || null,
            country: blCountry,
            linerRemarks: state?.rejectRemark || null,
            customerRemarks: state?.customerRemarks || null,
            status: status || null,
            emailTo: selectedDataNominatedAgent?.agentEmailId || null,
            accessList: agentAccessList.map((v: any) => {
              return {
                ...v,
                requestAccess: v?.allowAccess || null,
              };
            }),
            requestedPartnerId:
              selectedDataNominatedAgent?.requestedPartnerId || null,
            blId: selectedDataNominatedAgent?.blId || null,
            requestType: selectedDataNominatedAgent?.requestType,
          })
        )
          .unwrap()
          .then((response: any) => {
            toast.success(response?.responseMsg);
            dispatch(updateDashbaordRefresh(true))
          })
          .catch((error: any) => {
            toast.error(error);
          });

        //update instruction list and redirect to previous page
        setTimeout(() => {
          dispatch(
            getInstructionList({
              blNum: AESDecrypt(blNum),
              country: dataCountry
            })
          );
        }, 2000);
        onBack()
      }
    }
  };

  const _onAddressChange = useAsyncDebounce((value: string) => {
    var countryCode = 'USA';
    if (blCountry === 'US') {
      countryCode = 'USA';
    } else if (blCountry === 'SG') {
      countryCode = 'SGP';
    } else if (
      blCountry === 'ZA'
    ) {
      countryCode = 'ZAF';
    } else if (blCountry === 'KE') {
      countryCode = 'KEN';
    } else if (blCountry == 'JO') {
      countryCode = 'JOR'
    }
    dispatch(
      fetchAddressSuggestions2({
        text: value,
        country: countryCode,
      })
    );
  }, 200);

  useEffect(() => {
    if (watch('taxId') !== '' /*  && partnerId */ && !disableFields) {
      addr !== '' ? setAddress(addr) : setAddress('');
      compName !== '' ? setCompanyName(compName) : setCompanyName('');
      emailId !== '' ? setEmail(emailId) : setEmail('');

      partnerId !== '' ? setAgentPartnerId(partnerId) : setAgentPartnerId('');
      setValue('companyName', compName ? compName : '');
      setValue('email', emailId ? emailId : '');
      setValue('address', addr ? addr : '');
      setValue('agentPartnerId', partnerId ? partnerId : '');
    } else if (watch('taxId') && !partnerId && !agentPartnerId && !disableFields) {
      setAddress('');
      setCompanyName('');
      setEmail('');
      setAgentPartnerId('');
      setValue('companyName', '');
      setValue('email', '');
      setPartyName('');
      setPartyEmail('');
      setValue('address', '');
      setValue('agentPartnerId', '');
    }
    // } else if(!agentPartnerId && taxId !== '') {
    //     toast.error('Entered partner is not available on the ODeX, Kindly request Partner to get registered on the ODeX');
    // }
  }, [watch('taxId'), addr, compName, emailId, partnerId]);

  const rbac = useSelector((state: RootState) => state.userProfile);

  const [tncDialog, setShowTncDialog] = useState(false);

  const closeTncDialog = () => setShowTncDialog(false);
  const showTncDialog = () => setShowTncDialog(true);
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      new Promise(() => {
        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          setByteArr(reader.result ? reader.result.toString() : '');
        };
      });
      setFileName(file.name);
    }
  };
  const isAccess = (rowData: any) => {
    const { type, allowAccess } = rowData.row.original;

    const name = 'isAccess' + type.toString();
    return (
      <div className='row'>
        <div className='col-lg-4'>
          <input
            type='checkbox'
            id={name}
            name={name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              dispatch(
                updateAgentAccessList(
                  agentAccessList.map((x: any) => {
                    if (x.type === type) {
                      return {
                        ...x,
                        allowAccess: e?.target?.checked ? 'Y' : 'N',
                      };
                    } else {
                      return x;
                    }
                  })
                )
              );
            }}
            checked={allowAccess && allowAccess === 'Y' ? true : false}
            disabled={
              profileDetails.partnerType !== 'liner' && !disableFields
                ? false
                : status === 'Completed' || status === 'Submitted'
                  ? (rbac.rbacDetails.agentNominationApproveReject === 'Y' &&
                    profileDetails.partnerType === 'liner' &&
                    selectedDataNominatedAgent?.requestType &&
                    (selectedDataNominatedAgent?.requestType === 811 ||
                      selectedDataNominatedAgent?.requestType === 815)) ||
                    reverseDelegationValidCondition
                    ? //  ?  disableFields
                    status == 'Submitted' && ((selectedDataNominatedAgent?.requestType !== 811 &&
                      selectedDataNominatedAgent?.requestType !== 815) || profileDetails.partnerType == 'liner')
                      ? false
                      : disableFields
                    : true
                  : agentNominationId !== 0
            }
          />
        </div>
      </div>
    );
  };

  const blAccessColumns: IDataTableColumn[] = [
    {
      accessor: 'agentAccessId',
      Header: 'agentAccessId',
    },
    {
      accessor: 'agentNominationId',
      Header: 'agentNominationId',
    },
    {
      accessor: 'type',
      Header: 'Grant Invoice Access',
      Cell: (rowData: any) => {
        const { type } = rowData.row.original;
        return invTypeList?.find((v: any) => v.value === type)?.text;
      },
      width: 300,
      maxWidth: 300,
    },
    {
      accessor: 'allowAccess',
      Header: '',
      width: 200,
      Cell: isAccess,
      maxWidth: 150,
    },
  ];

  const reverseDelegationValidCondition =
    (blPartyList.find((v: any) => v.partyType == 'Shipper')?.partyId !==
      undefined &&
      blPartyList.find((v: any) => v.partyType == 'Shipper')?.partyId !==
      null) ||
      (blPartyList.find((v: any) => v.partyType == 'Consignee')?.partyId !==
        undefined &&
        blPartyList.find((v: any) => v.partyType == 'Consignee')?.partyId !==
        null)
      ? disabledPath != null &&
        disabledPath &&
        selectedDataNominatedAgent?.requestType &&
        agentNominationAllInstructionIds.includes(
          selectedDataNominatedAgent?.requestType
        ) &&
        (parseInt(
          blPartyList.find((v: any) => v.partyType == 'Shipper')?.partyId
        ) === profileDetails.partnerId ||
          parseInt(
            blPartyList.find((v: any) => v.partyType == 'Consignee')?.partyId
          ) === profileDetails.partnerId)
        ? true
        : false
      : false;

  return (
    <Card>
      <Card.Header className='border-bottom d-flex justify-content-between align-items-center sticky-top bg-white'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1'
          onClick={_onCloseForm}
        />
        {selectedTab === '' ? (
          <div className='row col-12'>
            <div className='col-lg-8'>
              <h2 className='fs-0 text-primary'>
                {' '}
                {docType && docType == '811'
                  ? t('resource_4:agent_nomination_to_carrier_from_consignee')
                  : docType && docType == '815'
                    ? t('resource_4:agent_nomination_to_carrier_from_shipper')
                    : docType && docType == '812'
                      ? t('resource_4:agent_nomination_to_consignee')
                      : docType && docType == '813'
                        ? t('resource_4:agent_nomination_to_shipper')
                        : (docType && docType == '814') ||
                          (location.pathname.split('/')[3] == 'instruction' &&
                            decrptins == '814')
                          ? t('resource_4:logisticsPartnerDelegationByShipper')
                          : t('resource_4:logisticsPartnerDelegationByConsignee')}
              </h2>
            </div>
            {/*<div className='col-lg-3'>
              {agentNominationId ? (
                <span>
                  <SoftBadge
                    bg={status === 'ACTIVE' ? 'success' : 'dark'}
                    className='mb-2'>
                    <h1 className='fs--1 m-0'>
                      {status === 'ACTIVE'
                        ? t('resource_1:active')
                        : t('resource_1:inActive')}
                    </h1>
                  </SoftBadge>
                </span>
              ) : (
                <></>
              )}
              </div> */}
          </div>
        ) : (
          ''
        )}
        {!disabledPath && selectedTab != '' ? (
          <>
            {t('resource_2:delegationRequest')} -{' '}
            {selectedTab === 'consignee' ? 'Consignee' : 'Liner'}
          </>
        ) : (
          ''
        )}
      </Card.Header>

      <Card.Body>
        <div className='container'>
          <Form onSubmit={onSubmit} autoComplete='off'>
            <div className='row'>
              {t('resource_message:agentDelegationMessage') != '' && (
                <div className='col-lg-12 mb-1'>
                  <Form.Label style={{ color: '#f5822a' }}>
                    <ul style={{ paddingLeft: '15px' }}>
                      {t('resource_message:agentDelegationMessage')
                        .split('\n')
                        .map((v: any) => (
                          <li>{v}</li>
                        ))}
                    </ul>
                  </Form.Label>

                </div>
              )}


              {/*  {selectedDataNominatedAgent &&
                selectedDataNominatedAgent?.requestTo &&
                selectedDataNominatedAgent?.requestTo !== null && (
                  <div className='col-lg-6 mb-3'>
                    <Form.Group controlId='tableComponent.requestTo'>
                      <span className='mandatory'>*</span>
                      <Form.Label>{t('resource_2:requestTo')}</Form.Label>
                      <div>
                        <Form.Check
                          type='radio'
                          name='requestTo'
                          id='requestToLiner'
                          label='Consignee'
                          checked={
                            selectedDataNominatedAgent?.requestTo === 'customer'
                              ? true
                              : false
                          }
                          className={'fs--1 form-check form-check-inline'}
                          disabled={true}
                        />
                        <Form.Check
                          type='radio'
                          name='requestTo'
                          id='requestToLiner'
                          label='Carrier'
                          checked={
                            selectedDataNominatedAgent?.requestTo === 'liner'
                              ? true
                              : false
                          }
                          disabled={true}
                          className={'fs--1 form-check form-check-inline'}
                        />
                      </div>
                    </Form.Group>
                  </div>
                )} */}

              <div className='col-lg-6 mb-3'>
                <Form.Group controlId='tableComponent.agentPartnerId'>
                  <Form.Control
                    type='hidden'
                    value={agentPartnerId}
                    {...register('agentPartnerId')}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='tableComponent.agentNominationId'>
                  <Form.Control
                    type='hidden'
                    value={agentNominationId}
                    {...register('agentNominationId')}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='tableComponent.agentTaxId'>
                  <Form.Label>
                    <span className='mandatory'>*</span>

                    {blCountry === 'SG'
                      ? t('resource_2:CompanyUEN')
                      : t('resource_2:taxId')}
                  </Form.Label>{' '}
                  <InputMask
                    disabled={disableFields}
                    {...register('taxId')}
                    className={`fs--1 form-control form-control-sm ${errors.taxId ? 'is-invalid' : ''
                      }`}
                    type='text'
                    mask={''}
                    maxLength={200}
                    onChange={(e: any) => setValue('taxId', e.target.value)}
                    value={watch('taxId')}
                    onBlur={(e: any) => {
                      if (watch('taxId').trim() !== '') {
                        dispatch(
                          fetchCompanyDetailsForAgentDelegation({
                            taxId: watch('taxId'),
                            country: blCountry,
                          })
                        );
                      } else {
                        setCompanyName('');
                        setValue('companyName', '');
                        setAddress('');
                        setValue('address', '');
                        setEmail('');
                        setValue('email', '');
                        setAgentPartnerId('');
                        setValue('agentPartnerId', '');
                      }
                      setValue('taxId', e.target.value);
                    }}
                  />
                  <div className='invalid-feedback'>
                    {errors.taxId?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              {(selectedDataNominatedAgent?.requestType == 812 ||
                selectedDataNominatedAgent?.requestType == 813 ||
                selectedDataNominatedAgent?.requestType == 811 ||
                selectedDataNominatedAgent?.requestType == 815) &&
                profileDetails?.partnerId == agentPartnerId ? (
                <>
                  <div className='col-lg-6 mb-3'>
                    <Form.Group controlId='tableComponent.partyName'>
                      <span className='mandatory'>*</span>
                      <Form.Label>
                        {decrptins == '812' || decrptins == '811'
                          ? t('resource_1:consignee')
                          : decrptins == '813' || decrptins == '815'
                            ? t('resource_1:shipper')
                            : t('resource_1:consignee') +
                            '/' +
                            t('resource_1:shipper')}
                      </Form.Label>
                      <Form.Control
                        disabled={true}
                        size='sm'
                        {...register('partyName')}
                        className={`fs--1 form-control ${errors.partyName ? 'is-invalid' : ''
                          }`}
                        type='text'
                        onChange={(e) => setPartyName(e.target.value)}
                        onBlur={(e) => {
                          setValue('partyName', e.target.value);
                          trigger('partyName');
                        }}
                        value={partyName}
                      />
                      <div className='invalid-feedback'>
                        {errors.partyName?.message?.toString()}
                      </div>
                    </Form.Group>
                  </div>
                  <div className='col-lg-6 mb-3'>
                    <Form.Group controlId='tableComponent.partyEmail'>
                      <span className='mandatory'>*</span>
                      <Form.Label>{t('resource_2:emailAddress')}</Form.Label>
                      <Form.Control
                        size='sm'
                        {...register('partyEmail')}
                        className={`fs--1 form-control ${errors.partyEmail ? 'is-invalid' : ''
                          }`}
                        type='email'
                        onChange={(e) => setPartyEmail(e.target.value)}
                        onBlur={(e) => {
                          setValue('partyEmail', e.target.value);
                          trigger('partyEmail');
                        }}
                        disabled={true}
                        value={partyEmail}
                      />
                    </Form.Group>
                  </div>
                </>
              ) : (
                <>
                  <div className='col-lg-6 mb-3'>
                    <Form.Group controlId='tableComponent.companyName'>
                      <Form.Label>
                        <span className='mandatory'>*</span>
                        {t('resource_2:companyName')}
                      </Form.Label>
                      <Form.Control
                        disabled={true}
                        size='sm'
                        {...register('companyName')}
                        className={`fs--1 form-control ${errors.companyName ? 'is-invalid' : ''
                          }`}
                        type='text'
                        onChange={(e) => setCompanyName(e.target.value)}
                        onBlur={(e) => {
                          setValue('companyName', e.target.value);
                          trigger('companyName');
                        }}
                        // value={
                        //   !matchPathInstruction()
                        //     ? selectedDataNominatedAgent?.agentPartnerName || ''
                        //     : companyName
                        // }
                        value={companyName}
                      />
                      <div className='invalid-feedback'>
                        {errors.companyName?.message?.toString()}
                      </div>
                    </Form.Group>
                  </div>

                  <div className='col-lg-6 mb-3'>
                    <Form.Group controlId='tableComponent.registeredEmail'>
                      <Form.Label>
                        <span className='mandatory'>*</span>
                        {t('resource_2:emailAddress')}
                      </Form.Label>
                      <Form.Control
                        size='sm'
                        {...register('email')}
                        className={`fs--1 form-control ${errors.email ? 'is-invalid' : ''
                          }`}
                        type='email'
                        maxLength={50}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={(e) => {
                          setValue('email', e.target.value);
                          trigger('email');
                        }}
                        disabled={disableFields}
                        // value={
                        //   !matchPathInstruction()
                        //     ? selectedDataNominatedAgent?.agentEmailId || ''
                        //     : email
                        // }
                        value={email}
                      />
                      <div className='invalid-feedback'>
                        {errors.email?.message?.toString()}
                      </div>
                    </Form.Group>
                  </div>
                </>
              )}

              <div className='col-lg-6 mb-3'>
                <Form.Group
                  style={{ position: 'relative' }}
                  controlId='tableComponent.address'
                >
                  <Form.Label>
                    <span className='mandatory'>*</span>
                    {t('resource_1:address')}
                  </Form.Label>
                  <Form.Control
                    {...register('address')}
                    className={`fs--1 form-control ${errors.address ? 'is-invalid' : ''
                      }`}
                    as='textarea'
                    onChange={(e) => {
                      dispatch(updateSelectedAddressResult(''));
                      setAddress(e.target.value);
                      _onAddressChange(e.target.value);
                    }}
                    onBlur={(e) => {
                      setValue('address', e.target.value);
                      trigger('address');
                    }}
                    disabled={true}
                    // value={
                    //   !matchPathInstruction()
                    //     ? selectedDataNominatedAgent?.address || ''
                    //     : address
                    // }
                    value={address}
                  />
                  <div className='invalid-feedback'>
                    {errors.address?.message?.toString()}
                  </div>

                  {address &&
                    !selectedAddressResult &&
                    !isAddressSuggesstionLoading &&
                    registrationAddressSuggesstion?.length > 0 ? (
                    <div
                      style={{ position: 'absolute' }}
                      className='w-100 rounded border bg-white  odex-registration-address d-flex flex-column justify-content-start align-items-start'
                    >
                      {registrationAddressSuggesstion.map((v: any) => (
                        <div
                          className='p-2 d-flex flex-row justify-content-start align-items-center fs--1 cursor-pointer'
                          key={v.placeId}
                          onClick={() => {
                            dispatch(updateSelectedAddressResult(v.text));
                            setAddress(v.text);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faLocation}
                            className='text-primary me-2'
                          />
                          <span>{v.text}</span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </div>

              {disableFields === true &&
                selectedDataNominatedAgent?.fileName ? (
                <div className='col-lg-12 mb-3'>
                  <Form.Label>
                    {blCountry === 'ZA' ||
                      blCountry == 'JO' ||
                      docType === '812' ||
                      docType === '813' ? (
                      <>{'LOI Upload'}</>
                    ) : (
                      ''
                    )}
                  </Form.Label>

                  <span
                    className='text-primary cursor-pointer'
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_CLIENT_APP_HOST
                        }/api/edo-rest-v2/v2/common/openDocument?id=${parseInt(
                          decrptdoc
                        )}&moduleTp=AGENT_NOMINATION&linerId=${profileDetails.partnerType === 'liner'
                          ? profileDetails.partnerId
                          : selectedLinerId
                        }`,
                        '_blank'
                      );
                    }}
                  >
                    {fileName}
                  </span>
                </div>
              ) : (
                ''
              )}

              {
                <div className='col-lg-6 mb-2'>
                  <Form.Group controlId='reqForm.remarks'>
                    <Form.Label>
                      {'Customer ' + t('resource_1:remark')}
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      maxLength={200}
                      value={state.customerRemarks}
                      onChange={(e) =>
                        setState({ ...state, customerRemarks: e.target.value })
                      }
                      disabled={!!disabledPath}
                    />
                  </Form.Group>
                </div>
              }
              {!disabledPath ? (
                ''
              ) : (
                <div className='col-lg-6 mb-2'>
                  <Form.Group controlId='reqForm.remarks'>
                    <Form.Label>{t('resource_2:rejectionRemark')}</Form.Label>
                    <Form.Control
                      as='textarea'
                      className='fs--1 form-control'
                      value={state.rejectRemark}
                      onChange={(e) =>
                        setState({ ...state, rejectRemark: e.target.value })
                      }
                      disabled={
                        (status !== 'Submitted' && status !== 'Completed') ||
                        ((rbac.rbacDetails.agentNominationApproveReject !==
                          'Y' ||
                          profileDetails.partnerType !== 'liner') &&
                          !reverseDelegationValidCondition)
                      }
                    />
                  </Form.Group>
                </div>
              )}
              {agentAccessList && agentAccessList.length > 0 ? (
                <div
                  className='col-lg-12 mt-2 mb-2 demmurageTable'
                  style={{ zIndex: 2 }}
                >
                  {/*   <span className='badge bg-secondary mb-2'>
                    {"Grant / invoice Access"}
                  </span> */}
                  <DataTable
                    data={agentAccessList}
                    gridName='BL_ACCESS'
                    columns={blAccessColumns}
                    sortable={false}
                    searchable={false}
                    showColumnFilter={false}
                    selection={false}
                    perPage={10}
                    pagination={false}
                    showExport={false}
                    uniqueFilterKey='blAccess'
                    autoHeight={true}
                    staticHiddenCols={['agentAccessId', 'agentNominationId']}
                  />
                </div>
              ) : (
                ''
              )}

              {/* <fieldset className='col-lg-12 mb-2'>
                <legend>Grand BL / invoice Access</legend>
                <div className='row'>
                  <div className='col-lg-3 mb-2'>
                    <Form.Group>
                      <Form.Check
                        type='checkbox'
                        id='tableComponent.input10'
                        label={
                          <Form.Label className='fs--1'>
                            <span>BL Access</span>
                            <span>
                              {' '}
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                title='BL Access'
                                className='me-2'
                              />
                            </span>
                          </Form.Label>
                        }
                        checked={isBlAccess}
                        onChange={onToggleBlAccess}
                        disabled={disableFields}
                      />
                    </Form.Group>
                  </div>
                  <div className='col-lg-3 mb-2'>
                    <Form.Group>
                      <Form.Check
                        type='checkbox'
                        id='tableComponent.input11'
                        label={
                          <Form.Label className='fs--1'>
                            <span>Freight Invoice</span>
                            <span>
                              {' '}
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                title='The PDF of the freight invoice is only available to the Bill to party. If the access is granted by the bill to party to the nominated Agent then only it will be visible for the agent'
                                className='me-2'
                              />
                            </span>
                          </Form.Label>
                        }
                        checked={isGrantFrtInvAccess}
                        onChange={onToggleGrantFrtInvAccess}
                        disabled={disableFields}
                      />
                    </Form.Group>
                  </div>
                  <div className='col-lg-3 mb-2'>
                    <Form.Group>
                      <Form.Check
                        type='checkbox'
                        id='tableComponent.input12'
                        label={
                          <Form.Label className='fs--1'>
                            <span>Time Pending Invoice</span>
                            <span>
                              {' '}
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                title='Time Pending Invoice'
                                className='me-2'
                              />
                            </span>
                          </Form.Label>
                        }
                        checked={isTimePendingInvoiceAccess}
                        onChange={onToggleTimePendingInvoiceAccess}
                        disabled={disableFields}
                      />
                    </Form.Group>
                  </div>
                </div>
                
                
              </fieldset> */}

              {agentNominationId != 0 && disableFields ? (
                <div className='col-lg-6 mb-2'>
                  <Form.Group>
                    <Form.Label>{t('resource_1:status')}</Form.Label>
                    <Form.Select
                      className='fs--1'
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                        setValue('status', e.target.value);
                      }}
                      disabled={disableFields}
                    >
                      <option
                        value='Completed'
                        selected={status == 'Completed' ? true : false}
                      >
                        Completed
                      </option>
                      <option
                        value='Submitted'
                        selected={status == 'Submitted' ? true : false}
                      >
                        Submitted
                      </option>
                      <option
                        value='Rejected'
                        selected={status == 'Rejected' ? true : false}
                      >
                        Rejected
                      </option>
                      <option
                        value='Cancelled'
                        selected={status == 'Cancelled' ? true : false}
                      >
                        Cancelled
                      </option>
                    </Form.Select>
                  </Form.Group>
                </div>
              ) : (
                <></>
              )}
              {(blCountry !== 'US') &&
                (!disableFields ||
                  (profileDetails.partnerType === 'customer' &&
                    agentNominationId !== profileDetails.partnerId &&
                    reverseDelegationValidCondition &&
                    agentPartnerId != profileDetails.partnerId &&
                    status == 'Submitted' && selectedDataNominatedAgent?.requestType !== 811 &&
                    selectedDataNominatedAgent?.requestType !== 815)) ? (
                <div className='col-lg-12 mb-2'>
                  <Form.Group>
                    <Form.Check
                      type='checkbox'
                      label={
                        <Form.Label className='fs--1'>
                          <span>
                            I accept Terms & Conditions to delegate the above
                            selected party
                          </span>
                        </Form.Label>
                      }
                      onClick={showTncDialog}
                      checked={termsAccepted}
                    //onChange={onToggleGrantFrtInvAccess}
                    //disabled={disableFields}
                    />
                  </Form.Group>
                </div>
              ) : (
                <></>
              )}

              {(rbac.rbacDetails.agentNominationApproveReject === 'Y' &&
                profileDetails.partnerType === 'liner' &&
                dataCountry === blCountry &&
                selectedDataNominatedAgent?.requestType &&
                (selectedDataNominatedAgent?.requestType === 811 ||
                  selectedDataNominatedAgent?.requestType === 815)) ||
                (profileDetails.partnerType === 'customer' &&
                  selectedDataNominatedAgent?.requestType !== 811 &&
                  selectedDataNominatedAgent?.requestType !== 815 &&
                  selectedDataNominatedAgent?.requestType !== 811 &&
                  selectedDataNominatedAgent?.requestType !== 815 &&
                  agentNominationId !== profileDetails.partnerId &&
                  reverseDelegationValidCondition &&
                  agentPartnerId != profileDetails.partnerId)
                ? !instructionList
                  .filter(
                    (v: any) =>
                      decrptdoc && v?.decrptdoc === parseInt(decrptdoc)
                  )
                  .map((u: any) => u?.event === 'Rejected')
                  .includes(true) && (
                  <div className='d-flex justify-content-center align-items-center'>
                    {status !== 'Rejected' &&
                      status !== 'Completed' &&
                      status !== 'Cancelled' && (
                        <Button
                          disabled={
                            blCountry == 'US'
                              ? false
                              : data?.list?.find?.(
                                (i: any) => i?.blId == AESDecrypt(blNum)
                              )?.status === 'Completed' ||
                                data?.list?.find?.(
                                  (i: any) => i?.blId == AESDecrypt(blNum)
                                )?.status === 'Rejected'
                                ? disableFields
                                : profileDetails.partnerType === 'customer' &&
                                  agentNominationId !==
                                  profileDetails.partnerId &&
                                  reverseDelegationValidCondition &&
                                  agentPartnerId != profileDetails.partnerId
                                  ? !termsAccepted
                                  : false
                          }
                          onClick={(e) => {
                            onApproveReject('A', 'AGENT_NOMINATION');
                          }}
                          variant='success'
                          className='me-4 approve'
                          size='sm'
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            className='me-2 approve'
                          />
                          {t('resource_1:approve')}
                        </Button>
                      )}

                    {((decrptins == '811' && status != 'Rejected') ||
                      (decrptins == '815' && status != 'Rejected') ||
                      (decrptins == '812' && status != 'Rejected') ||
                      (decrptins == '813' && status != 'Rejected')) && (
                        <Button
                          disabled={
                            data?.list?.find?.(
                              (i: any) => i?.blId == AESDecrypt(blNum)
                            )?.status === 'Rejected'
                              ? disableFields
                              : false
                          }
                          onClick={(e) => {
                            onApproveReject('R', 'AGENT_NOMINATION');
                          }}
                          variant='danger reject'
                          size='sm'
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className='me-2 reject'
                          />
                          {t('resource_1:reject')}
                        </Button>
                      )}
                  </div>
                )
                : !disabledPath &&
                disableFields == false &&
                !instructionList
                  .filter(
                    (v: any) =>
                      decrptdoc && v?.decrptdoc === parseInt(decrptdoc)
                  )
                  .map(
                    (u: any) =>
                      u?.event === 'Completed' || u?.event === 'Rejected'
                  )
                  .includes(true) && (
                  <div className='col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center'>
                    <Button
                      variant='falcon-primary me-2'
                      size='sm'
                      type='submit'
                      disabled={
                        blCountry == 'US'
                          ? !agentPartnerId
                          : blCountry == 'SG' ||
                            blCountry == 'ZA' ||
                            blCountry == 'JO' ||
                            blCountry == 'KE'
                            ? !termsAccepted || !agentPartnerId
                            : !agentPartnerId
                      }
                    >
                      <FontAwesomeIcon icon={faSave} className='me-2' />
                      {t('resource_1:submit')}
                    </Button>

                    <Button
                      variant='falcon-danger'
                      onClick={() => {
                        dispatch(clearFields({}));
                        resetForm();
                        //formReset();
                        dispatch(toggleAddAgent(false));
                        dispatch(toggleNominationReq(false));
                        dispatch(updateSelectedTab(''));
                        dispatch(toggleGrantFrtInvAccess(false));
                        setTermsAccepted(false);
                      }}
                      size='sm'
                    >
                      <FontAwesomeIcon icon={faRedo} className='me-2' />
                      {t('resource_1:reset')}
                    </Button>
                  </div>
                )}

              {disableFields == true &&
                selectedDataNominatedAgent?.requestedPartnerId ===
                profileDetails.partnerId &&
                rbac.rbacDetails.nominactionCUD == 'Y' &&
                (decrptins === '805' || decrptins === '814') &&
                status === 'Completed' ? (
                <div className='col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center'>
                  <Button
                    variant='falcon-primary me-2'
                    size='sm'
                    type='button'
                    disabled={
                      status === 'Completed' && profileDetails
                        ? !disableFields
                        : true
                    }
                    onClick={() => {
                      dispatch(toggleDisableFields(false));
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} className='me-2' />
                    {t('resource_1:edit')}
                  </Button>
                  <Button
                    variant='falcon-danger'
                    size='sm'
                    type='button'
                    disabled={status === 'Completed' ? !disableFields : true}
                    onClick={(e) => {
                      onApproveReject('C', 'AGENT_NOMINATION');
                    }}
                  >
                    <FontAwesomeIcon icon={faCancel} className='me-2' />
                    {t('resource_2:cancelDelegation')}
                  </Button>
                </div>
              ) : disableFields === false &&
                agentNominationId &&
                rbac.rbacDetails.nominactionCUD == 'Y' ? (
                <div className='col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center'>
                  <Button
                    variant='falcon-primary me-2'
                    size='sm'
                    disabled={
                      blCountry == 'US'
                        ? !agentNominationId
                        : blCountry == 'SG' ||
                          blCountry == 'ZA' ||
                          blCountry == 'JO' ||
                          blCountry == 'KE'
                          ? !termsAccepted || !agentNominationId
                          : !agentNominationId
                    }
                    onClick={onSubmit}
                  >
                    <FontAwesomeIcon icon={faSave} className='me-2' />
                    {t('resource_1:submit')}
                  </Button>

                  <Button
                    variant='falcon-danger'
                    onClick={_onCloseForm}
                    size='sm'
                  >
                    <FontAwesomeIcon icon={faRedo} className='me-2' />
                    {t('resource_1:cancel')}
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Form>
          {
            <Modal
              show={tncDialog}
              onHide={closeTncDialog}
              size='lg'
            >
              <Modal.Header className='p-2 pb-1'>
                <Modal.Title>{t('resource_1:disclaimer')}</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  overflow: 'auto',
                  fontSize: '12px',
                }}
              >
                <AgentDelegationTNC />
                <div className='col-lg-8 offset-2 mt-3 d-flex justify-content-center align-items-center'>
                  <Button
                    variant='falcon-primary'
                    size='sm'
                    className='me-2'
                    onClick={() => {
                      setTermsAccepted(true);
                      setShowTncDialog(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faSave} className='me-2' />
                    {t('resource_1:agree')}
                  </Button>

                  <Button
                    variant='falcon-danger'
                    size='sm'
                    onClick={() => {
                      setTermsAccepted(false);
                      setShowTncDialog(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} className='me-2' />
                    {t('resource_1:disagree')}
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          }
        </div>
      </Card.Body>
    </Card>
  );
};

export default NominateAgent;
