import { createSlice, isAsyncThunkAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

/**
 * Reducer Slice for handling loading state for the
 * Loading Middleware
 */

const initialState = {
globalLoading: false,
loadingList: {},
thunkList:{},
pendingActionList: {}
};

const loadingSlice = createSlice({
name: 'loadingSlice',
initialState,
reducers: {
  updateGlobalLoading: (state, action)=>{
    state.globalLoading = action.payload
  },
  addToLoadingList: (state, action) =>{
      state.loadingList = {...state.loadingList, ...action.payload}
  },
  removeFromLoadingList: (state, action) =>{
      delete state.loadingList[action.payload]
  },
  incrementLoadCount: (state, action)=>{
    state.loadingList[action.payload] += 1
  },
  decrementLoadCount: (state, action)=>{
    state.loadingList[action.payload] -= 1
  },
  addToThunkList(state, action){
    state.thunkList[action.payload.key] = action.payload.value
  },
  removeFromThunkList(state,action){
    delete state.thunkList[action.payload]
  },
  addToPendingActionList(state, action){
    if(Object.hasOwn(state.pendingActionList, action.payload)) state.pendingActionList[action.payload]++
    else state.pendingActionList[action.payload] = 1
  },
  removeFromPendingActionList(state,action){
    state.pendingActionList[action.payload]--
    if(state.pendingActionList[action.payload] <= 0) delete state.pendingActionList[action.payload]
  }
},
});

const {updateGlobalLoading, addToLoadingList, removeFromLoadingList,
  addToThunkList, removeFromThunkList,
  addToPendingActionList, removeFromPendingActionList
} = loadingSlice.actions


/* 
Add non-critical thunk action names to be ignored by global loading
in the ignore array below,

You can find this as the first param of the createAsyncThunk() initialization 
of that particular action
*/
const ignore = [
  'userDetails/getRbacDetails',
  'pymtModedropdown/getInstructionAgentTypes',
  'pymtModedropdown/getInstructionTypes',
  'agentDelegation/getPartyListByBlId',
  'instruction/getTrukerNameList',
  'registrationCompany/fetchAddressSuggestions2',
  'registrationCompany/fetchAddressSuggestions',
  'getCurrencyDDList',
  'instruction/getCFSNameList',
  'instruction/getInvoiceDetailsByPmtId',
  'instruction/getInstruction'
];

let pendingActions = 0;

/**
 * use the useLoading hook to add any custom loading states outside of
 * the global loading
 * 
 * pass an Array of all the thunk actions you want to track for the loading
 * The hook will return a boolean state variable that will turn true if the
 * actions are pending
 */
const useLoading = (thunkIds)=>{
  const dispatch = useDispatch()
  
  const key = useRef(null);
  const loading = useSelector((state)=>state.loading.loadingList[key.current])
  const pendingActionCount = useSelector((state)=>thunkIds.reduce((a,b)=>a+ (state.loading.pendingActionList[b]??0),0))

  useEffect(()=>{
    key.current = crypto.randomUUID();
  },[])

  useEffect(()=>{
    if(key.current == null) return
    dispatch(addToThunkList({key:key.current, value:thunkIds}))
    
    dispatch(addToLoadingList({[key.current]:pendingActionCount}))
    //return ()=>{
    //  dispatch(removeFromThunkList(key.current))
    //  dispatch(removeFromLoadingList(key.current))
    //}
  },[pendingActionCount, key])
  return loading !== 0
}
// a global count of all keys from every instance of useLoading

/**
 * Redux middleware detects when a dispatch is a thunk action
 * and mantains a pending actions count, updating the global Loading
 * state when count hits 0
 *
 * NOTE: This middleware should always be AFTER thunk in the middleware
 * chain of the store
 */
const loadingMiddleware = (store) => (next) => (action) => {
  if (
    isAsyncThunkAction(action) &&
    ignore.every((type) => !action.type.includes(type))
  ) {
    if (action?.meta?.requestStatus === 'pending') {
      pendingActions++;
      store.dispatch(updateGlobalLoading(true));
    } else {
      pendingActions--;
      if (pendingActions === 0) store.dispatch(updateGlobalLoading(false));
    }
  }

  if(isAsyncThunkAction(action)){
        const type = action.type.substring(0,action.type.lastIndexOf('/'))
        if (action?.meta?.requestStatus === 'pending') {
          //store.dispatch(incrementLoadCount(key));
          store.dispatch(addToPendingActionList(type))
        } else {
          //store.dispatch(decrementLoadCount(key));
          store.dispatch(removeFromPendingActionList(type))
        }
  }

  
  return next(action);
};

export default loadingMiddleware;
export {useLoading, loadingSlice}
