import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import useDataCountry from "./useDataCountry";
import { useLoading } from "../middleware/loadingMiddleware";
import { useState } from "react";

/**
 * NOTE: it does NOT make any API calls,
 * make sure the appropriate APIs are called for 
 * oblDetails / blEvent / instDashboard view
 * 
 * @param event 
 * @param type 
 * @param blEventId 
 * @returns 
 */
export default function useBlCountry( 
    event:"new"|"view",
    type:"import"|"export", 
    ){
    const [viewCountry, setViewCountry] = useState(null)
    const loadingNew = useLoading(['instruction/getOBLDetails'])
    const dataCountry = useDataCountry();
    const newCountry = useSelector((state:RootState)=>{
            const form = state.oblDetails.oblForm
            if(type == "import"){
                return (form.fpod && form.fpod.trim() != ''?form.fpod:form.pod)?.substring(0,2)
            }
            return (form.startPol && form.startPol.trim() != ''?form.startPol:form.pol)?.substring(0,2); 
   })

   if (event == 'view'){
    return [viewCountry??dataCountry, setViewCountry]
   }
   return [loadingNew?null:newCountry??dataCountry, setViewCountry]
}