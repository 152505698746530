import { getBadges } from '../app/features/dashboard/components/helpers';
import { IDataTableColumn } from '../app/common/types/dataTable';
import {
    Link,
    matchPath,
    useNavigate,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faChevronCircleRight,
} from '@fortawesome/free-solid-svg-icons';
import { AESEncrypt } from '../app/../encrypt-util';
import { toast } from 'react-toastify';
import { faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { useAmountFormatter } from './useAmountFormatter';
import { useDispatch } from 'react-redux';
import { gateInfoGateIn, gateInfoGateOut } from '../app/features/blRequestInstruction/components/actions/Instruction';
import { AppDispatch, RootState } from '../app/store';
import { useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import useDataCountry from './useDataCountry';



export interface IRequestConfig {
    accessor: string | null;
    columnId: string | number | null;
    dataType: string | null;
    filterType: string | null;
    filterValues: any[] | null;
    filterable: string | boolean | null;
    fixed: string | boolean | null;
    hide: boolean | null;
    hyperlink: string | null;
    label: string | null;
    sort_order: string | null;
    sortable: string | boolean | null;
    timeFormat: string | null;
    tooltip: string | null;
    type: string | null;
    visible: string | boolean | null;
    width: string | number | null;
    link: string | null | any;
    hyperLink: string | null;
    slider: string | null | any;
    assert?: any;
}
export interface IConfigsDefinition {
    columns: IDataTableColumn[];
    staticHiddenCols: string[];
    defaultHiddenCols: string[];
}

export function useGenerateColumnConfig() {
    const formatNumber = useAmountFormatter()
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const dataCountry = useDataCountry()
    const LinerId = useSelector(
        (state: RootState) => state.navbarTop.linerId
      );
    
    const profileDetails = useSelector(
            (state: RootState) => state.userProfile.profile
            );
    return (
        configs: IRequestConfig[],
        getParams?: () => any
    ): IConfigsDefinition => {
        const generateHeaderLabel = (s: any) => {
            let count = 1;
            for (let i in s) {
                if (s.charAt(i) === s.charAt(i).toUpperCase()) {
                    count++;
                }
            }
            return count ? `resource_${count}:${s}` : s;
        };
        const columnConfigs: any = configs.map((c) => {
            const commonKeys = {
                accessor: c?.accessor || null,
                Header: c?.accessor ? generateHeaderLabel(c.accessor) : '',
                minWidth: c?.width
                    ? typeof c?.width === 'string'
                        ? parseInt(c.width) - 60
                        : c.width - 60
                    : 120,
                width: c?.width
                    ? typeof c?.width === 'string'
                        ? parseInt(c.width)
                        : c.width
                    : 120,
                maxWidth: c?.width
                    ? typeof c?.width === 'string'
                        ? parseInt(c.width) + 100
                        : c.width + 100
                    : 300,
                columnFilterType: c?.filterType || null,
                columnFilterOptions: c?.filterValues || null,
                disableSort: c?.sortable === 'false' ? true : false,
                fixed: c?.fixed === 'true' || c?.fixed ? true : false,
                link: c?.link || null,
                hyperLink: c?.hyperLink || null,
                slider: c?.slider || null,
                hide: c?.hide || false,
            };


            return {
                ...commonKeys,
                Cell: (rowData: any) => {
                    if(c?.accessor == "gateIN"|| c?.accessor == "gateOut"){
                         const gateIn = c.accessor == "gateIN"
                        return <Button   onClick={()=>{
                            //const gateIn = c.accessor == "gateIN"
                                            const payload:any = [rowData.row.original].map((x:any)=>({
                                            blId: x.bl_id,
                                            containerNo:x.container_no,
                                            gateIn:gateIn?new Date() || '':null,
                                            gateInTo:gateIn?profileDetails.partnerName:null,
                                            gateOut:!gateIn?new Date() || '':null,
                                            gateOutFrom: !gateIn?profileDetails.partnerName:null,
                                            linerId: LinerId,
                                            countryCode: dataCountry,
                                            doNumber:x.do_number,
                                            doDate:x.do_date ,
                                            validTillDate:x.do_validity_date,
                                            emptyReturnDate:x.empty_return_date,
                                            emptyYardName:x.empty_yard_name,
                                            emptyYardAddress:x.empty_yard_address,
                                            companyName:x.customer_name
                            
                                          }))
                                         
                                          dispatch(gateIn?gateInfoGateIn(payload):gateInfoGateOut(payload))
                        }} size={'sm'} disabled={true} variant='falcon-primary'  className='me-0 mt-0 p-1 fs--1'>
                            {gateIn? <span><FontAwesomeIcon icon={'arrow-right'} className='fs--1'/> Gate In </span>
                            :<span><FontAwesomeIcon  icon={'arrow-left'} className='fs--1 ' style={{color:"#f5822a"}}/> Gate Out
                            </span> }</Button>
                      }
                    const value = c?.accessor
                        ? c?.dataType === 'datetime' || c?.dataType === 'date'
                            ? rowData?.row?.original?.[c.accessor]
                                ? new Date(rowData?.row?.original?.[c.accessor])
                                : ''
                            : rowData?.row?.original?.[c.accessor]
                        : '';
                    const getSelection = (path: string, encrypt = true) => {
                        const params = getParams ? getParams() : {};

                        let returnResponse = false;
                        //  const pattern = /:[A-Za-z]*/g;
                        const pattern = /:\w*/g;

                        const variables = path.match(pattern);

                        const combineLogicFields = ['inst_id', 'doc_id'];
                        const combineLogicFields2 = ['request_id'];
                        if (c?.accessor === 'inst_type' && combineLogicFields?.length > 0) {
                            let comibneLogicDecider = [];
                            if (
                                variables &&
                                variables?.length > 0 &&
                                matchPath(path, window.location.pathname)
                            ) {
                                for (let i of variables) {
                                    const variable = i.slice(1);
                                    const value = rowData?.row?.original?.[variable];

                                    if (
                                        combineLogicFields.includes(variable) &&
                                        value &&
                                        variable &&
                                        params?.[variable] === value
                                    ) {
                                        comibneLogicDecider.push(variable);
                                    }
                                }
                            }

                            return comibneLogicDecider?.length === combineLogicFields?.length;
                        }
                        else if (c?.accessor === 'request_id' && combineLogicFields?.length > 0) {
                            let comibneLogicDecider = [];
                            if (
                                variables &&
                                variables?.length > 0 &&
                                matchPath(path, window.location.pathname)
                            ) {
                                for (let i of variables) {
                                    const variable = i.slice(1);
                                    const value = rowData?.row?.original?.[variable];
                                    if (
                                        combineLogicFields2.includes(variable) &&
                                        value &&
                                        variable &&
                                        params?.[variable] === value
                                    ) {

                                        comibneLogicDecider.push(variable);
                                    }
                                }
                            }

                            return comibneLogicDecider?.length === combineLogicFields2?.length;
                        } else if (c?.accessor == 'instruction_name') {
                            if (
                                variables &&
                                variables?.length > 0 &&
                                window.location.pathname.includes(':bl_id')
                            ) {
                                for (const i of variables) {
                                    const variable = i.slice(1);
                                    const value = rowData?.row?.original?.[variable];
                                    if (value && variable && params?.[variable] === value) {
                                        returnResponse = true;
                                    }
                                }
                            }
                        }
                        else {
                            if (
                                variables &&
                                variables?.length > 0 &&
                                matchPath(path, window.location.pathname)
                            ) {
                                for (let i of variables) {
                                    const variable = i.slice(1);
                                    const value = rowData?.row?.original?.[variable];

                                    if (value && variable && params?.[variable] === value) {
                                        returnResponse = true;
                                    }
                                }
                            }
                        }

                        return returnResponse;
                    };

                    const getRouteLink = (path: string, encrypt = true) => {
                        let returnPath = path;

                        const pattern = /:[A-Za-z_]*/g;

                        const variables = path.match(pattern);
                        if (variables && variables?.length > 0) {
                            for (let i of variables) {
                                const variable = i.slice(1);

                                const value = rowData?.row?.original?.[variable];

                                if (value)
                                    returnPath = returnPath.replace(
                                        i,
                                        encrypt &&
                                            //   variable !== 'inst_id' &&
                                            //  variable !== 'doc_id' &&
                                            variable !== 'id' &&
                                            variable !== 'instructionid' &&
                                            variable !== 'doc_number'
                                            ? AESEncrypt(value)
                                            : value
                                    );
                            }
                        }

                        if (
                            variables?.includes(':bl_id') &&
                            variables?.includes(':standing_instruction_id')
                        ) {
                            const parts = returnPath.split('/');
                            if (parts.length >= 3) {
                                const thirdPosition = parts[3];
                                parts[3] = AESEncrypt(thirdPosition);

                                return parts.join('/');
                            }
                        }
                        return returnPath;
                    };

                    const setTimeFormat = (val: string) => {
                        try {
                            return c?.dataType === 'date' || c?.dataType === 'datetime'
                                ? val
                                    ? c?.dataType === 'date'
                                        ? new Date(val).toLocaleDateString(
                                            new Intl.DateTimeFormat().resolvedOptions().locale,
                                            { timeZone: 'UTC' }
                                        )
                                        : new Date(val).toLocaleString()
                                    : ''
                                : val;
                        } catch (e) {
                            return val;
                        }
                    };

                    const getLink = (value: string | null | any) => {
                        if (c?.accessor === 'snoozed' || c?.accessor === 'snoozenotification') {
                            return (
                                <div>
                                    {/*  <FontAwesomeIcon
                    icon={faBell}
                     className='text-primary ps-1'
                     size='lg'
                    /> */}
                                    <Link
                                        to={getRouteLink(`${c?.link}${window.location.search || ''}`)}
                                        className={`${getSelection(c?.link) ? 'fw-bold' : 'text-primary'
                                            }`}
                                    >
                                        {value === 'Yes' ?
                                            <FontAwesomeIcon
                                                icon={faBell}
                                                className='text-primary ps-1'
                                                size='lg'
                                            /> : value === 'No' ? <FontAwesomeIcon
                                                icon={faBellSlash}
                                                className='text-danger ps-1 '
                                                size='lg'
                                            /> : ''}
                                    </Link>

                                </div>
                            )
                        }
                        else if (c?.link && c?.slider) {
                            return (
                                <div>
                                    <Link
                                        to={
                                            rowData?.row?.original?.[c?.assert?.field] !==
                                                c?.assert?.value
                                                ? getRouteLink(window.location.pathname)
                                                : window?.location?.search
                                                    ? getRouteLink(`${c?.link}${window.location.search}`)
                                                    : getRouteLink(c?.link)
                                        }
                                        onClick={(event) => {
                                            if (
                                                c?.accessor == 'bl_no' ||
                                                c?.accessor == 'blno' ||
                                                c?.accessor == ''
                                            ) {
                                                if (
                                                    rowData?.row?.original?.[c?.assert?.field] !==
                                                    c?.assert?.value
                                                ) {
                                                    toast.error(
                                                        c?.assert?.errormsg || 'Unable to open link'
                                                    );
                                                    event.preventDefault()
                                                } else {
                                                }
                                            }
                                        }}
                                        className={`${getSelection(c?.link) || getSelection(c?.slider)
                                                ? 'fw-bold'
                                                : 'text-primary'
                                            }`}
                                    >
                                        {value}
                                    </Link>
                                    {value&&<span
                                        onClick={() => {
                                            if (c?.accessor == 'bl_no' || c?.accessor == 'blno') {
                                                if (
                                                    rowData?.row?.original?.[c?.assert?.field] !==
                                                    c?.assert?.value
                                                ) {
                                                    toast.error(
                                                        c?.assert?.errormsg || 'Unable to open link'
                                                    );
                                                } else {

                                                        navigate(
                                                            window?.location?.search
                                                                ? `${getRouteLink(c?.slider)}${window.location.search
                                                                }`
                                                                : getRouteLink(c?.slider)
                                                        );
                                                }
                                            }
                                        }}
                                        className='infoIcon cursor-pointer ms-1'
                                    >
                                        <FontAwesomeIcon icon={faChevronCircleRight} />
                                    </span>}
                                </div>
                            );
                        } else if (
                            c?.link &&
                            !(rowData?.row?.original?.doc_category === 202) &&
                            value
                        ) {
                            return (
                                <Link
                                    to={getRouteLink(`${c?.link}${window.location.search || ''}`)}
                                    className={`${getSelection(c?.link) ? 'fw-bold' : 'text-primary'
                                        }`}
                                >
                                    {value}
                                </Link>
                            );
                        } else if (c?.slider && value) {
                            return (
                                <div>
                                    <span className={`${getSelection(c?.slider) ? 'fw-bold' : ''}`}>
                                        {value}
                                    </span>
                                    <span
                                        onClick={() => {
                                            if (navigate)
                                                navigate(
                                                    window?.location?.search
                                                        ? `${getRouteLink(c?.slider)}${window.location.search
                                                        }`
                                                        : getRouteLink(c?.slider)
                                                );
                                        }}
                                        className='infoIcon cursor-pointer ms-1'
                                    >
                                        <FontAwesomeIcon icon={faChevronCircleRight} />
                                    </span>
                                </div>
                            );
                        } else if (c?.hyperlink) {
                            if (value?.startsWith('CD') && c?.accessor == 'invno') {
                                return <span>{value}</span>
                            }

                            if (
                                rowData?.row?.original?.[c?.assert?.field] === c?.assert?.value
                            ) {
                                return (
                                    <span
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(
                                                `${process.env.REACT_APP_CLIENT_APP_HOST}${getRouteLink(
                                                    c?.hyperlink || '',
                                                    false
                                                )}`,
                                                '_blank'
                                            );
                                        }}
                                        className='text-primary cursor-pointer'
                                    >
                                        {value}
                                    </span>
                                );
                            } else {
                                return (
                                    <span
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toast.error(c?.assert?.errormsg || 'Unable to open link');
                                        }}
                                        className='text-primary cursor-pointer'
                                    >
                                        {value}
                                    </span>
                                );
                            }
                        } else {
                            if (c?.accessor == 'invamt' || c?.accessor == 'pmt_amt' || c?.accessor == 'localamt' || c?.accessor == 'amount') {
                                const newVal = formatNumber(value, 
                                    {ccy:c?.accessor == 'localamt'?'KES':
                                    c?.accessor == 'pmt_amt'?rowData?.row?.original?.currency:
                                    rowData?.row?.original?.invcurr}
                                );
                                return <div className='text-end'>{newVal}</div>;
                            } else {
                                return value;
                            }
                        }
                    };

                    if (c?.tooltip) {
                        const tooltipValue = rowData?.row?.original?.[c.tooltip];

                        return (
                            <div
                                className='text-truncate'
                                data-toggle='tooltip'
                                title={tooltipValue || value}
                            >
                                {c?.type === 'badge'
                                    ? getBadges(value)
                                    : getLink(setTimeFormat(value || ''))}
                            </div>
                        );
                    } else {
                        if (c?.accessor === 'pmtstatus') {
                            return value === 'Yes' ? (
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className='text-primary ps-1'
                                    size='lg'
                                />
                            ) : value === 'No' ? (
                                ''
                            ) : (
                                <div
                                    className='text-truncate'
                                    data-toggle='tooltip'
                                    title={value}
                                >
                                    {c?.type === 'badge' ? getBadges(value) : ''}
                                </div>
                            );
                        }
                        return (
                            <div
                                className='text-truncate'
                                data-toggle='tooltip'
                                title={setTimeFormat(value || '')}
                            >
                                {c?.type === 'badge'
                                    ? getBadges(value)
                                    : getLink(setTimeFormat(value || ''))}
                            </div>
                        );
                    }
                },
            };
        });
        return {
            columns: columnConfigs,
            staticHiddenCols: configs
                .filter((c: any) => c?.hide === 'true' || c?.hide)
                .map((c: any) => c.accessor),
            defaultHiddenCols: configs
                .filter((c: any) => c?.visible === 'false')
                .map((c: any) => c.accessor),
        };
    };
}

