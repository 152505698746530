/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';

import { useTranslation } from 'react-i18next';
import {
    useNavigate,
    useParams,
    useLocation
} from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
    updateInstructionID,
    updateSelectedEventIdDH,
} from '../../features/blDetails/slices/documentHistorySlice';
import { AESDecrypt } from '../../../encrypt-util';

import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';


import { useBackNavigation } from '../../../hooks/BackNavigation';


const EmailBodyDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { email_logs_id } = useParams();
    const email_id = AESDecrypt(email_logs_id)
    const location = useLocation();

    const onBack = useBackNavigation()

    const navigate = useNavigate();

    const backNavUrl = useSelector(
        (state: RootState) => state.documentView.backNavUrl
    );

    const data = useSelector(
        (state: RootState) => state.dashboard.emailDashboardList
    );
    const [body, setBody] = useState("")

    useEffect(()=>{
        setBody(data.result.find(email => email.email_logs_id === email_id)?.email_body??"")
    },[email_id])


    const _onCloseForm = () => {

        dispatch(updateSelectedEventIdDH(null));

        onBack()
        // if (location.pathname.includes("emailDashboard")) {
        //     navigate(location.pathname.replace(/\/emailDashboard.*/, "/emailDashboard"));
        // }

        // setTimeout(() => {
            // navigate(-1);
        // }, 500);

        // onBack()
    };


    return (
        <Card style={{ height: '100%', overflow: 'auto' }}>
            {email_id && (
                <Card.Header className='d-flex justify-content-between align-items-center border-bottom p-3 sticky-top bg-white'>
                    <FontAwesomeIcon
                        style={{ position: 'absolute', left: '1rem' }}
                        icon={faArrowAltCircleLeft}
                        onClick={_onCloseForm}
                        className='cursor-pointer fa-lg'
                    />
                    <h2 className='fs-0 text-primary mb-0 ms-4'>
                        {' '}
                        {/* {t('resource_3:BillofLadingInformation')} */}
                        Email Body
                    </h2>
                </Card.Header>
            )}

            <Card.Body className='p-0'>

                <div className='container'>
                    <div className='mt-1 d-flex flex-column justify-content-start align-items-start containerDetails '>
                        {/* <h2 className='fs-0 mb-2 ms-0'> Email log id : {email_id}</h2> */}

                        {body ? (
                            <div
                            dangerouslySetInnerHTML={{ __html: body}}
                        />
                        ) : (
                            <p>No email body found.</p> 
                        )}

                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default EmailBodyDetails;
