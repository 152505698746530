import { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form } from 'react-bootstrap';

import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import { AppDispatch, RootState } from '../../store';
import DataTable from '../../common/components/DataTable';
import { clearGetInvoiceList } from './slices/getInvoiceSlice';
import { IDataTableColumn } from '../../common/types/dataTable';
import { useBackNavigation } from '../../../hooks/BackNavigation';
import { getInvoiceRequest, submitGetInvoiceRequest } from './actions/getInvoiceRequest';
import { toggleDocumentHistoryColumnShort } from '../blDetails/slices/documentHistorySlice';
import { updateDashbaordRefresh } from '../../common/components/DataTable/slices/DataTable';

const GetInvoiceRequest = () => {
  const [blId, setBlId] = useState<any>('');

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const onBack = useBackNavigation()

  const { data } = useSelector((data: any) => data?.getInvoiceSlice) ?? { data: [] }
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);

  const getInvoiceDetailsColumns: IDataTableColumn[] = [
    {
      accessor: 'invoice_number',
      Header: "Invoice Number",
      minWidth: 138,
      width: 138,
      maxWidth: 150,
    },
    {
      accessor: 'invoice_amount',
      Header: "Invoice Amount",
      minWidth: 138,
      width: 138,
      maxWidth: 150,
    },
    {
      accessor: 'invoice_currency',
      Header: "CCY",
      minWidth: 90,
      width: 90,
      maxWidth: 102,
    },
    {
      accessor: 'invoice_type',
      Header: "Invoice Type",
      minWidth: 200,
      width: 200,
      maxWidth: 200,
    }
  ];

  const validationSchema = Yup.object().shape({
    blNum: Yup.string()
      .required('BL Number is required')
      .test(
        'blNum',
        'Invalid BL Number',
        () => !(blId === '' || blId === null || blId === undefined)
      ),
  });

  const {
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const _onCloseForm = () => {
    dispatch(toggleDocumentHistoryColumnShort(false));
    onBack();
  };

  const getInvoices = (blNumber: string) => {
    if (blNumber) {
      dispatch(getInvoiceRequest({ blNo: blNumber ?? '', linerId: linerId ?? '' }))
    }
    else {
      dispatch(clearGetInvoiceList())
    }
  }

  const onSubmit = async () => {
    try {
      const response = await dispatch(submitGetInvoiceRequest({
        blNo: getValues('blNum') ?? '',
        linerId: linerId ?? ''
      }))?.unwrap();

      const { responseCode, responseMsg } = response ?? { responseCode: '', responseMsg: '' };

      if (responseMsg) {
        if (responseCode?.toLowerCase() === 'success') {
          toast.success(responseMsg);
          dispatch(updateDashbaordRefresh(true));
        } else {
          toast.error(responseMsg);
        }
        onBack();
      }
    } catch (err: any) {
      const errorMessage = err?.response?.responseMsg ?? "Unable to release the invoices. Please try again later!";
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    dispatch(clearGetInvoiceList());
  }, []);

  return (
    <Card style={{ minHeight: '100%' }}>
      <Card.Header className='border-bottom d-flex justify-content-between align-items-center sticky-top bg-white'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1'
          onClick={_onCloseForm}
        />
        <div className='row col-12'>
          <div className='col-lg-12'>
            <h2 className='fs-0 text-primary'>
              {t('resource_2:getInvoice')}
            </h2>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className='container'>
          <div className='row'>
            <div className='d-flex flex-column gap-y-1'>
              {t("resource_12:getInvoiceNote").split('\n').map((data, index) =>
                <Form.Label style={index == 1 ? { color: '#f5822a', direction: 'rtl' } : { color: '#f5822a' }}>
                  {data.split('\b').map((innerData) => (
                    <>
                      <span>{innerData.replaceAll(`'`, `"`)}</span>
                      <br />
                    </>
                  ))}
                </Form.Label>
              )}
            </div>
            <div className='col-lg-6 mb-3'>
              <Form.Group controlId='tableComponent.registeredEmail'>
                <span className='mandatory'>*</span>
                <Form.Label>{t("resource_1:bl_no")}</Form.Label>
                <Form.Control
                  size='sm'
                  {...register('blNum')}
                  className={`fs--1 form-control form-control-sm ${errors.blNum ? 'is-invalid' : ''}`}
                  type='text'
                  maxLength={20}
                  onKeyDown={(e) => {
                    if (e?.key == 'Enter') {
                      getInvoices(getValues('blNum'))
                    }
                  }}
                  onChange={(e) => {
                    const upperCaseBLNumValue = e.target.value.trim().toUpperCase();
                    setValue('blNum', upperCaseBLNumValue);
                    setBlId('');
                  }}
                  onBlur={(e) => {
                    getInvoices(e.target.value)
                  }}
                  value={watch('blNum')}
                />
                <div className='invalid-feedback'>
                  {errors.blNum?.message?.toString()}
                </div>
              </Form.Group>
            </div>
            <DataTable
              data={data}
              columns={getInvoiceDetailsColumns}
              gridName='INVOICE_DETAILS'
              sortable={true}
              searchable={false}
              showColumnFilter={false}
              selection={false}
              perPage={10}
              pagination={false}
              showExport={false}
              uniqueFilterKey='invoiceDetails'
              autoHeight={true}
              maxHeight='29.5vh'
              staticHiddenCols={[]}
            />
            <div className="d-flex justify-content-center">
              <Button
                style={{ width: 'max-content' }}
                disabled={data && data.length == 0}
                variant='falcon-primary'
                size='sm'
                onClick={onSubmit}
              >
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="me-2" style={{ color: '#f68b4f' }} />
                {t('resource_2:getInvoice')}
              </Button>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default GetInvoiceRequest;
