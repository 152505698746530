import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetQuickPayTable } from "../quickpay/quickPaySlice";
import { useBackNavigation } from "../../../hooks/BackNavigation";
import { updateDashbaordRefresh } from "../../common/components/DataTable/slices/DataTable";
function App({ payload, quickPay }) {
  const paypalRef = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onBack = useBackNavigation();

  useEffect(() => {
    let orderId = "";
    const paypal = window.paypal;
    var FUNDING_SOURCES = {
      7: [
        { fundingSource: paypal.FUNDING.PAYPAL, color: "blue" },
        { fundingSource: paypal.FUNDING.VENMO },
        { fundingSource: paypal.FUNDING.PAYLATER },
      ],
      8: [
        { fundingSource: paypal.FUNDING.CREDIT },
        { fundingSource: paypal.FUNDING.CARD },
      ],
    };
    const selectedFundingSource = (FUNDING_SOURCES[payload?.pymtMode] ?? [
      { fundingSource: paypal.FUNDING.CARD },
    ])[0].fundingSource;
    if (paypalRef?.current) paypalRef.current.innerHTML = "";

    (FUNDING_SOURCES[payload?.pymtMode] ?? []).forEach(function ({
      fundingSource,
      color,
    }) {
      // Initialize the buttons

      const button = window.paypal?.Buttons({
        fundingSource: fundingSource,
        style: {
          color: color,
          label: "checkout",
          shape: "pill",
        },
        createOrder: function (data, actions) {
          return axios
            .post(
              "/api/payment-gateway/v1/gateway/paypal/order/initiation",
              payload,

              {
                headers: {
                  Accept: "*/*",
                },
              }
            )
            .then(function (response) {
              //window.open(response?.data?.links[1]?.href, '_blank', 'height=500,width=600');
              orderId = response?.data?.id;
              return response?.data?.id;
            })
            .catch(function (error) {
              console.error("Error creating order:", error);
              throw error;
            });
        },

        onApprove: function (data, actions) {
          return axios
            .post(
              `/api/payment-gateway/v1/gateway/paypal/order/${orderId}/capture`,
              {
                headers: {
                  accept: "*/*",
                  "Content-Type": "application/json",
                },
              }
            )
            .then(function (response) {
              if (response?.data?.error) {
                if (
                  response?.data?.error.details?.[0]?.issue ==
                  "INSTRUMENT_DECLINED"
                ) {
                  actions.restart();
                  return;
                }
                throw new Error(
                  response?.data?.error.details?.[0]?.description ??
                    "Payment Unsuccessful"
                );
              }
              if (quickPay) {
                dispatch(resetQuickPayTable());
                navigate("/quickpay");
                toast.success(
                  `Payment successful, details will be mailed to ${payload.email}`,
                  {
                    theme: "colored",
                  }
                );
                return;
              }
              toast.success("Payment successful", {
                theme: "colored",
              });
              onBack();
              setTimeout(()=>{
                dispatch(updateDashbaordRefresh(true))
              }, 2000)
            })
            .catch(function (error) {
              console.error("Error creating order:", error);
              throw error;
            });
        },
        onCancel: (data, action) => {
          toast.error("Payment Cancelled");
        },
        onError: (err) => {
          console.error("Paypal Error: ", err.message);
          toast.error(err.message);
        },
      });

      // Check if the button is eligible
      if (button.isEligible()) {
        // Render the standalone button for that payment method
        button.render(paypalRef.current);
      }
    });
  }, [payload?.pymtMode]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      {payload?.pymtMode == 8 && (
        <span className="fs--1 mb-2 mt-0 " style={{ color: "#f5822a" }}>
          <b>
            Only U.S.-issued cards are accepted. Please use a U.S.-based card.
          </b>
        </span>
      )}
      {payload?.pymtMode == 7 && (
        <span className="fs--1 mb-2 mt-0 " style={{ color: "#f5822a" }}>
          <b>
            Requires a PayPal account. No account? Use Credit/Debit Card
            instead.
          </b>
        </span>
      )}
      <div style={{ width: "240px" }} ref={paypalRef}></div>
    </div>
  );
}

export default App;
