import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { boolean } from 'yup';


interface IEmailDashSlice {
  showColumnSettings:boolean;
  selection:any
}

const initialState: IEmailDashSlice = {
  showColumnSettings:false,
  selection:{}
};

export const emailDashboardSlice = createSlice({
  name: 'themeSettings',
  initialState,
  reducers: {
    toggleColumnSettingsEmailDash:(state,action)=>{
        state.showColumnSettings = action.payload
    },
    updateSelectedRow:(state,action)=>{
      state.selection = action.payload
  }
  },
});

export const { toggleColumnSettingsEmailDash,updateSelectedRow} = emailDashboardSlice.actions;

export default emailDashboardSlice.reducer;
