import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getReleasePOPDetails, getUnpaidInvoice } from "../dashboard/actions/instruction";

interface IUnpaid{
    data:Array<any>;
    paymentData:Array<any>;
    releasePOPData:Array<any>;
}

const initialState:IUnpaid ={
    data:[{}],
    paymentData:[{}],
    releasePOPData:[]
}

export const unpaidSlice = createSlice({
    name:'unpaid',
    initialState,
    reducers:{
        clearUnpaidData:(state)=>{
            state.data = [{}]
            state.paymentData = [{}]
        },
        clearPOPLInerData:(state)=>{
            state.releasePOPData = []
        }

    },
    extraReducers:(builder)=>{
        builder.addCase(getUnpaidInvoice.fulfilled, (state, action:any)=>{
            state.data = action.payload.unpaid;
            state.paymentData = action.payload.attachment;
        })
        builder.addCase(getReleasePOPDetails.fulfilled, (state,action:any)=>{
            state.releasePOPData = action.payload;
        })
    }
})
export const {clearPOPLInerData, clearUnpaidData} = unpaidSlice.actions

export default unpaidSlice.reducer