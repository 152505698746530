import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getInstructionList, getInstructionTypes } from '../../dashboard/actions/instruction';

export interface DocumentHistoryDetails {
  blId?: any;
  document: string;
  event: string;
  documentNo: string;
  time: string;
  amount: string;
  currency?: string;
  payable?: string;
  paid?: string;
  docId?: string;
  instructionId: any;
  user: string;
  docStatus?: string;
  partnerId?: any;
  blEventId?:number | null;
  country:string;
}

export interface AmountDetails {
  payable: string;
  received: string;
  due: string;
}

export interface DocumentStatus {
  instruction: string;
  payment: string;
  release: string;
  vessel?: string;
  eta?: string;
}

export interface BLPartyDetails {
  partyId: string;
  partyName: string;
  partyType: string;
}

interface DocumentHistorySlice {
  data: DocumentHistoryDetails[];
  isDocumentHistoryLoading: boolean;
  documentStatus: DocumentStatus;
  amountDetails: AmountDetails;
  showDocumentHistoryColumnSettings: boolean;
  isDocumentHistoryColumnShort: boolean;
  dataLoading: boolean;
  selectedEventId: number | string | null;
  selectedDocId: number | string | null;
  instructionTypes: any;
  selectedRouter: any;
  instructionIdurl: any;
  blPartyList: BLPartyDetails[];
  loadInstructList: boolean
}

const initialState: DocumentHistorySlice = {
  isDocumentHistoryLoading: false,
  isDocumentHistoryColumnShort: false,
  dataLoading: true,
  instructionIdurl: '',
  selectedRouter: '',
  amountDetails: {
    payable: '',
    received: '',
    due: '',
  },
  documentStatus: {
    instruction: '',
    payment: '',
    release: '',
    vessel: '',
    eta: '',
  },
  data: [],
  showDocumentHistoryColumnSettings: false,
  selectedEventId: null,
  selectedDocId: null,
  instructionTypes: [],
  blPartyList: [],
  loadInstructList: false,
};

export const documentHistorySlice = createSlice({
  name: 'documentHistory',
  initialState,
  reducers: {
    updateInstructionID: (state, action: PayloadAction<any>) => {
      state.instructionIdurl = action.payload;
    },
    toggleDocumentHistoryLoader: (state, action: PayloadAction<boolean>) => {
      state.isDocumentHistoryLoading = action.payload;
    },
    toggleDocumentHistoryColumnShort: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDocumentHistoryColumnShort = action.payload;
    },
    updateDocumentStatus: (state, action: PayloadAction<DocumentStatus>) => {
      state.documentStatus = action.payload;
    },
    updateAmountDetails: (state, action: PayloadAction<AmountDetails>) => {
      state.amountDetails = action.payload;
    },
    toggleDHColumnSettings: (state, action: PayloadAction<boolean>) => {
      state.showDocumentHistoryColumnSettings = action.payload;
    },
    updateSelectedEventIdDH: (
      state,
      action: PayloadAction<string | number | null>
    ) => {
      state.selectedEventId = action.payload;
    },
    updateSelectedDocIdDH: (
      state,
      action: PayloadAction<string | number | null>
    ) => {
      state.selectedDocId = action.payload;
    },
    updateInstructionTypes: (state, action: PayloadAction<boolean>) => {
      state.instructionTypes = action.payload;
    },
    updateBLPartyList: (state, action: PayloadAction<BLPartyDetails[]>) => {
      state.blPartyList = action.payload;
    },
    updateSelectedRoute: (state, action: PayloadAction<string>) => {
      state.selectedRouter = action.payload;
    },
    clearDocumentHistory:(state)=>{
      state.data = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getInstructionList.pending, (state, action) => {
      state.dataLoading = true;
      state.data = []
    });
    builder.addCase(getInstructionList.fulfilled, (state, action) => {
      state.dataLoading = false;

      state.data = action.payload.list;
    });
    builder.addCase(getInstructionTypes.pending, (state,_)=>{
      state.loadInstructList = true
    });
    builder.addCase(getInstructionTypes.fulfilled, (state,_)=>{
      state.loadInstructList = false
    });
  },
});

export const {
  toggleDocumentHistoryLoader,
  updateDocumentStatus,
  updateAmountDetails,
  toggleDHColumnSettings,
  toggleDocumentHistoryColumnShort,
  updateSelectedEventIdDH,
  updateInstructionTypes,
  updateBLPartyList,
  updateInstructionID,
  updateSelectedDocIdDH,
  updateSelectedRoute,
  clearDocumentHistory
} = documentHistorySlice.actions;

export default documentHistorySlice.reducer;
