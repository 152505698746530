import { useTranslation } from "react-i18next";
import DataTable from "../../common/components/DataTable";
import InfoDiv from "../../../components/common/InfoDiv";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { AESEncrypt } from "../../../encrypt-util";

export default function AttachmentsPaymentTable(){
    const {t} = useTranslation()
    const data = useSelector((state:RootState)=>state.invoiceUnpaid.paymentData)
    const navigate = useNavigate()
    const location = useLocation()
  const attColumns = [
  {
    accessor: 'number',
    Header: 'Reference Number',
    width: 350,
    maxWidth: 200
  },
  {
    accessor: 'ccy',
    Header: 'CCY',
    width: 120,
    maxWidth: 80
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    width: 150,
    maxWidth: 80
  },
  {
    accessor:'action',
    Header:"",
    width:200,
    maxWidth: 200,
    Cell:(rowData:any)=>{
      const {id} = rowData.row.original
      return <div className='d-flex align-items-center'>
                <InfoDiv title={"Click to this button will navigate you to Proof of payment submission page for this payment."}>
                  <Button variant='falcon-primary' size='sm'
                    onClick={() => { navigate(`payment/${AESEncrypt(id)}`) }}
                  ><FontAwesomeIcon icon={faUpload} className="me-1" /> Payment Proof</Button>
            
                </InfoDiv>
            </div>
    }
  }
  ];
    return <div className="mb-4"><DataTable
            gridName="MISSING_ATT_TABLE"
            data={data}
            columns={attColumns} 
            sortable={false}
            searchable={false}
            showColumnFilter={false}
            selection={false}
            perPage={10}
            pagination={false}
            showExport={false}
            uniqueFilterKey='missingAttTable'
            autoHeight={true}
            staticHiddenCols={[]}
            tableMinHeight={50}
    /></div>
}