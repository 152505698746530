import classNames from 'classnames';
import AppContext from '../../../context/Context';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  updateCurrentPageBlDashboard,
  updateSetDefaultViewBL,
  updateSetDefaultViewInvoice,
  updateCurrentPageInvoiceDashboard,
  updateCurrentPageUserDashboard,
  updateCurrentPageRegistrationDashboard,
  updateCurrentPageReceiptDashboard,
  updateCurrentPageEmailDashboard,
} from '../../../app/features/dashboard/slices/dashboardSlice';

const CollapseItems = ({ route }) => {
  const { pathname } = useLocation();
  const openCollapse = (childrens) => {
    const checkLink = (children) => {
      if (children.to === pathname) {
        return true;
      }
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children.children.some(checkLink)
      );
    };
    return childrens.some(checkLink);
  };

  const [open, setOpen] = useState(openCollapse(route.children));

  return (
    <Nav.Item as='li'>
      <Nav.Link
        onClick={() => {
          setOpen(!open);
        }}
        className={classNames('dropdown-indicator cursor-pointer', {
          'text-500': !route.active,
        })}
        aria-expanded={open}
        // {...route}
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      <Collapse in={open}>
        <Nav className='flex-column nav' as='ul'>
          <NavbarVerticalMenu routes={route.children} />
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool,
  }).isRequired,
};



const NavbarVerticalMenu = ({ routes }) => {
  const {
    config: { showBurgerMenu },
    setConfig,
  } = useContext(AppContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const activeIndex = useSelector((state)=> {
    const getName = {
      BL_DASHBOARD_LINER :'BL Dashboard',
      INVOICE_DASHBOARD_LINER: 'Invoice Dashboard',
      INSTRUCTION_DASHBOARD_LINER: 'Instruction',
      BL_DASHBOARD_CUSTOMER :'BL Dashboard',
      INVOICE_DASHBOARD_CUSTOMER: 'Invoice Dashboard',
      INSTRUCTION_DASHBOARD_CUSTOMER: 'Instruction',
      USER_DASHBOARD: 'Users',
      STANDING_INSTRUCTION: 'Standing Instruction',
      PAYMENT_DASHBOARD: 'Payment Details',
      PAYMENT_RECEIPT: 'Receipt',
      // EMAIL_TEMPLATE_DASHBOARD: 'Email Dashboard',
      EMAIL_LOGS: 'Email Dashboard',
      CUSTOMER_DASHBOARD: 'Customer Mapping',
      GET_IN_OUT_DASHBOARD:'GetIn GetOut',
    }
    return routes.findIndex(
      (route)=>route.name === getName[state.navbarTop.selectedDashbaordName])
  })

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
    dispatch(updateCurrentPageBlDashboard(1));
    dispatch(updateCurrentPageInvoiceDashboard(1));
    dispatch(updateCurrentPageReceiptDashboard(1));
    dispatch(updateCurrentPageEmailDashboard(1));
    dispatch(updateCurrentPageUserDashboard(1));
    dispatch(updateCurrentPageRegistrationDashboard(1))
    dispatch(updateSetDefaultViewBL(true));
    dispatch(updateSetDefaultViewInvoice(true));
  };

  return routes.map((route, i) => {
    if (!route.children) {
      return (
        <Nav.Item as='li' key={route.name} onClick={handleNavItemClick}>
          <NavLink
            end={route.exact}
            to={route.to}
            state={{ open: route.to === '/authentication-modal' }}
            className={({ isActive }) => {
              if (route.to === '#!') return 'nav-link';
              if (isActive) return 'active nav-link';
              if (
                routes.some((route) => matchPath(route.to, location.pathname))
              )
                return 'nav-link';
              if (i === activeIndex) return 'active nav-link';
              return 'nav-link';
            }}
          >
            <NavbarVerticalMenuItem route={route} />
          </NavLink>
        </Nav.Item>
      );
    }
    return <CollapseItems route={route} key={route.name} />;
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired,
};

export default NavbarVerticalMenu;
