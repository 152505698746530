import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getOBLDetails } from '../../features/dashboard/actions/instruction';

interface IOblForm {
  number: string | null;
  pod: string | null;
  pol: string | null;
  etd: string | null;
  eta: string | null;
  consigneeNm: string | null;
  fpod: string | null;
  blType: string | null;
  shipmentNo: string | null;
  startPol: string | null;
  movementType: string | null;
  haulage: string | null;
  isAgentUser: string | null;
  freightInvAccess: string | null;
}

interface IVesselDetails {
  vesselType: any;
  preInternalVoyage: string | null;
  preExternalVoyage: string | null;
  preVesselName: string | null;
  preETA: string | null;
  preETD: string | null;
  mainInternalVoyage: string | null;
  mainExternalVoyage: string | null;
  mainVesselName: string | null;
  mainETA: string | null;
  mainETD: string | null;
  onInternalVoyage: string | null;
  onExternalVoyage: string | null;
  onVesselName: string | null;
  onETA: string | null;
  onETD: string | null;
}

interface IOblDetailsList {
  list: any[];
  next: string | null;
  previous: string | null;
  partyList: any[];
  blJourneyList: any[];
}

export interface IOblDetails {
  data: IOblDetailsList;
  isOblDetailsLoading: boolean;
  showHaulage: boolean;
  oblForm: IOblForm;
  vesselDetails: IVesselDetails;
  truckerCodearray: any[];
}

const initialState: IOblDetails = {
  data: {
    list: [],
    next: null,
    previous: null,
    partyList: [],
    blJourneyList: [],
  },
  isOblDetailsLoading: false,
  showHaulage: false,
  truckerCodearray: [],
  oblForm: {
    number: '',
    pod: '',
    pol: '',
    etd: '',
    eta: '',
    consigneeNm: '',
    fpod: '',
    blType: '',
    shipmentNo: '',
    startPol: '',
    movementType: '',
    haulage: '',
    isAgentUser: '',
    freightInvAccess: '',
  },
  vesselDetails: {
    vesselType: '',
    preInternalVoyage: '',
    preExternalVoyage: '',
    preVesselName: '',
    preETA: '',
    preETD: '',
    mainInternalVoyage: '',
    mainExternalVoyage: '',
    mainVesselName: '',
    mainETA: '',
    mainETD: '',
    onInternalVoyage: '',
    onExternalVoyage: '',
    onVesselName: '',
    onETA: '',
    onETD: '',
  },
};

export const OblDetailSlice = createSlice({
  name: 'oblDetails',
  initialState,
  reducers: {
    updateIOblDetailsList: (state, action: PayloadAction<IOblDetailsList>) => {
      state.data = action.payload;
    },
    toggleCarrierHaulage: (state, action: PayloadAction<boolean>) => {
      state.showHaulage = action.payload;
    },
    updateTruckerCodearray: (state, action: PayloadAction<any[]>) => {
      state.truckerCodearray = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOBLDetails.pending, (state, action) => {
      state.isOblDetailsLoading = true;
      state.oblForm = {
    number: '',
    pod: '',
    pol: '',
    etd: '',
    eta: '',
    consigneeNm: '',
    fpod: '',
    blType: '',
    shipmentNo: '',
    startPol: '',
    movementType: '',
    haulage: '',
    isAgentUser: '',
    freightInvAccess: '',
  }
    });
    builder.addCase(getOBLDetails.fulfilled, (state, action) => {
      state.isOblDetailsLoading = false;
      state.data = {
        next: null,
        previous: null,
        list: action.payload.containerList?.map((v: any) => ({
          containerNo: v?.containerNo || null,
          type: v?.containerType || null,
          size: v?.containerSize || null,
          trucker: v?.truckerCode || null,
          releaseDate: v?.releaseDate || null,
          releaseStatus: v?.releaseStatus || null,
          lastFreeDate: v?.lastDate || null,
          releasePin: v?.releasePin || null,
          releaseLoc: v?.releaseLoc || null,
        })),

        partyList: action.payload.partyList?.map((v: any) => ({
          partyId: v?.partyId || null,
          partyName: v?.partyName || null,
          partyType: v?.partyTypeName || null,
          internalCode: v?.internalCode || null,
          customerCode: v?.customerCode || null,
        })),

        blJourneyList: action.payload.blJourneyList?.map((v: any) => ({
          blJourneyId: v?.blJourneyId || null,
          type: v?.type || null,
          externalVoyageNo: v?.externalVoyageNo || null,
          internalVoyageNo: v?.internalVoyageNo || null,
          vesselName: v?.vesselName || null,
          pol: v?.pol || null,
          pod: v?.pod || null,
          eta: v?.eta || null,
          etd: v?.etd || null,
        })),
      };

      state.oblForm = {
        number: action?.payload?.number || '',
        pod: action?.payload?.pod || '',
        pol: action?.payload?.pol || '',
        etd: action?.payload?.etd || '',
        eta: action?.payload?.eta || '',
        consigneeNm: action?.payload?.consigneeNm || '',
        blType: action?.payload?.blType || '',
        startPol: action?.payload?.startPol || '',
        fpod: action?.payload?.finalPod || '',
        shipmentNo: action?.payload?.shipmentNo || '',
        movementType: action?.payload?.transportType || '',
        haulage: action?.payload?.imp_haulage || '',
        isAgentUser: action?.payload?.isAgentUser || '',
        freightInvAccess: action?.payload?.freightInvAccess || '',
      };

      state.vesselDetails = {
        vesselType: '',
        mainInternalVoyage: action?.payload?.mainInternalVoyageNo || '',
        mainExternalVoyage: action?.payload?.mainExternalVoyageNo || '',
        mainVesselName: action?.payload?.mainVesselName || '',
        mainETA: action?.payload?.maineta || '',
        mainETD: action?.payload?.maineta || '',
        preInternalVoyage: action?.payload?.preInternalVoyageNo || '',
        preExternalVoyage: action?.payload?.preExternalVoyageNo || '',
        preVesselName: action?.payload?.preVesselName || '',
        preETA: action?.payload?.preeta || '',
        preETD: action?.payload?.preetd || '',
        onInternalVoyage: action?.payload?.onInternalVoyageNo || '',
        onExternalVoyage: action?.payload?.onExternalVoyageNo || '',
        onVesselName: action?.payload?.onVesselName || '',
        onETA: action?.payload?.oneta || '',
        onETD: action?.payload?.onetd || '',
      };
    });
  },
});

export const {
  updateIOblDetailsList,
  toggleCarrierHaulage,
  updateTruckerCodearray,
} = OblDetailSlice.actions;

export default OblDetailSlice.reducer;
