import { toast } from 'react-toastify';

import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from "../../../../axiosSetup";

export const getInvoiceRequest = createAsyncThunk('getInvoices', async (payload: any) => {
    try {
        const { data } = await axiosInstance.post("/api/edo-rest-v2/v2/invoice/getNonFreightInvoicesByBlNo", payload)??[];
        const responseCode = data?.responseCode?.toLowerCase();
        
        if (responseCode === 'success') {
            const filteredInvoices =data?.invoiceList
                ?.filter(({ accessStatus }: { accessStatus: string }) => accessStatus === 'N')
                ?.map(({ invoiceNo, invoiceAmount, invType,invoiceCurrency }: { invoiceNo: string, invoiceAmount: number, invType: string,invoiceCurrency:string }) => ({
                    invoice_number: invoiceNo,
                    invoice_amount: invoiceAmount,
                    invoice_type: invType,
                    invoice_currency:invoiceCurrency
                }));


            if (!filteredInvoices?.length) {
                toast.error('Invoices for the B/L are already available on the invoice dashboard.');
                return [];
            }

            return filteredInvoices;
        } else {
            toast.error(data?.responseMessage);
            return [];
        }
    } catch (error) {
        toast.error('Something went wrong. Please try again later.');
        return [];
    }
});

export const submitGetInvoiceRequest = createAsyncThunk('submitGetInvoiceRequest',
    async (payload:any) => {
        const response =(await axiosInstance.post("/api/edo-rest-v2/v2/bl/releseNonFreightInvocies", payload)).data??[];
        return response;
    }
);