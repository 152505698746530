import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useBackNavigation } from "../../../hooks/BackNavigation";
import DataTable from "../../common/components/DataTable";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUnpaidInvoice } from "../dashboard/actions/instruction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AESEncrypt } from "../../../encrypt-util";
import useDataCountry from "../../../hooks/useDataCountry";
import InfoDiv from "../../../components/common/InfoDiv";
import AttachmentsPaymentTable from "./AttachmentPaymentsTable";

export default function InvoiceUnpaid() {
  const { t } = useTranslation()
  const onBack = useBackNavigation()
  const data = useSelector((state: RootState) => state.invoiceUnpaid.data)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const location = useLocation()
  const rbac = useSelector((state: RootState) => state.userProfile.rbacDetails)
  const datacountry = useDataCountry()
  const blid: any = useSelector(
    (state: RootState) => state.documentHistory.data?.[0]?.blId
  );

  const unreleased = data.find((item) => !item.ccy || item.ccy === '')
  const paymentData = useSelector((state:RootState)=>state.invoiceUnpaid.paymentData)
  const unreleasedOnly = data.filter((item) => item.ccy && item.ccy !== '').length == 0
  useEffect(() => {
    if (blid)
      dispatch(getUnpaidInvoice({ blid, country: datacountry }))
  }, [blid])
  useEffect(() => {
    if (data.length == 0 && paymentData.length == 0 ) navigate(location.pathname.replace('unpaid', `instruction/${AESEncrypt('804')}`))
  }, [data, paymentData])

  return <Card style={{ minHeight: '100%' }}>
    <Card.Header className='d-flex align-items-center border-bottom sticky-top bg-white'>
      <FontAwesomeIcon
        icon={faArrowAltCircleLeft}
        className='text-warning cursor-pointer fa-lg mb-1 me-1'
        onClick={onBack}
      />

      <h2 className='fs-0 text-primary mb-1'>
        {t('resource_2:unpaid_invoice')}
      </h2>
    </Card.Header>
    <Card.Body>
      <div className='container'>
        <div className='row'>

        {(data?.length > 0 || paymentData?.length > 0) && <ul className='mb-0 fs--1' style={{ paddingLeft: '30px',color: '#f5822a' }}>
            {t('resource_7:actionRequiredCrrMessage')
              .split('\n')
              .map((v: any) => (
                <li>{v}</li>
              ))}
          </ul>
        }
        {rbac.crrPopAttStop == 'Y' && paymentData?.length > 0 && <ul className='mb-2 fs--1' style={{ paddingLeft: '30px',color: '#f5822a' }}>
            {t('resource_7:mandatePOPMessage')
              .split('\n')
              .map((v: any) => (
                <li>{v}</li>
              ))}
          </ul>
        }
        {rbac.crrPopAttStop == 'Y' && paymentData.length > 0 && <AttachmentsPaymentTable/>}

         
          {data.length <= 0 ? "" :rbac.crrUnpaidHardStop == 'Y'  ?
          <ul className='mb-2 fs--1' style={{ paddingLeft: '30px',color: '#f5822a' }}>
            {t('resource_7:unpaidInvoiceMessage')
              .split('\n')
              .map((v: any) => (
                <li>{v}</li>
              ))}
          </ul> :
          <span className='mb-2 fs--1'
          style={{ color: '#f5822a' }}
        >
       {t('resource_7:unpaidInvoiceSoftMessage')}
        </span>}

          {unreleased &&
            <span className='mb-2 fs--1'
              style={{ color: '#2c7be5' }}
            >{t('resource_7:unreleasedInvoiceMessage')}</span>
          }
        </div>
        {
          // the initial state array has length 1 since otherwise the screen will
          // automatically redirect to CRR
          // so we need to check the keys of the obj to make sure its API response
          // and not the placeholder 
          data?.length > 0 &&
          Object.keys(data[0]).length > 0 &&
          <DataTable
            data={data}
            gridName="INVOICE_UNPAID"
            columns={unpaidColumns}
            sortable={false}
            searchable={false}
            showColumnFilter={false}
            selection={false}
            perPage={10}
            pagination={false}
            showExport={false}
            uniqueFilterKey='invoiceunpaid'
            autoHeight={true}
            staticHiddenCols={['invid']}
          />}
        <div className='d-flex align-items-center mt-4'
          style={{ justifyContent: 'space-evenly' }}>

          {data?.length > 0 && <div className='d-flex align-items-center'>
            <InfoDiv title={t('resource_message:popPayInfo')}>
              <Button variant='falcon-primary' size='sm'
                onClick={() => { navigate(`${location.pathname}/payment`) }}
                disabled={data?.length == 0 || unreleasedOnly}
              >{t('resource_1:proceedpay')}
              </Button>
            </InfoDiv>

          </div>}
          {(rbac.crrUnpaidHardStop != 'Y' || (( data?.length == 0 ) && ( paymentData?.length == 0 ))) &&
            <div className='d-flex align-items-center'>
              <InfoDiv title={t('resource_message:popIgnoreInfo')}>
                <Button variant='falcon-primary' size='sm'
                  onClick={() => {
                    navigate(location.pathname.replace('unpaid', `instruction/${AESEncrypt('804')}`))
                  }}>{t('resource_1:proceedrelease')}
                </Button>
              </InfoDiv>
            </div>
          }

        </div>
      </div>
    </Card.Body>
  </Card>
}

const unpaidColumns = [
  {
    accessor: 'invno',
    Header: 'Invoice Number',
    width: 200,
    maxWidth: 150
  },
  {
    accessor: 'ccy',
    Header: 'CCY',
    width: 120,
    maxWidth: 100
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    width: 150,
    maxWidth: 100
  },
  {
    accessor: 'invtype',
    Header: 'Invoice Type',
    width: 200,
    maxWidth: 150
  },
];
