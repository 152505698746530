import { useEffect, useMemo, useRef, useState } from 'react';

import moment from 'moment';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Button, Card, Dropdown, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { matchPath, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppDispatch, RootState } from '../../store';
import DataTable from '../../common/components/DataTable';
import DocumentHistory from '../blDetails/DocumentHistory';
import FilterView from '../../common/components/FilterView';
import OblDetails from '../../common/components/OblDetails';
import { useInvDashName } from '../../../hooks/useDashName';
import modalImage from '../../../assets/images/PaypalUSA.png'
import { IConfigsDefinition } from '../../utils/columnConfig';
import { AESDecrypt, AESEncrypt } from '../../../encrypt-util';
import Header from '../../common/components/DetailsViewHeader';
import GetInvoiceRequest from '../blPayment/GetInvoiceRequest';
import { IDataTableColumn } from '../../common/types/dataTable';
import { getColumnViews } from '../../common/actions/dataTable';
import { getDashboardData } from '../dashboard/actions/dashboard';
import { showNotificationModal, updateDashbaordName } from '../../common/slices/navbarTop';
import { setEditPayment } from '../blPayment/slices/blPaymentSlice';
import { useAmountFormatter } from '../../../hooks/useAmountFormatter';
import StandaloneInvoicePayment from '../blPayment/StandaloneInvoicePayment';
import DashboardContainer from '../../../components/common/DashboardContainer';
import { setPayPalPopUp, toggleColumnSettingsPaymentList } from './paymentSlice';
import { useGenerateColumnConfig } from '../../../hooks/useGenerateColumnConfig';
import { updateCurrentPageInvoiceDashboard } from '../dashboard/slices/dashboardSlice';
import { updateDashbaordRefresh, updateParams } from '../../common/components/DataTable/slices/DataTable';
import { updateSelectedBlListMultiPay, updateSelectedBlListMultiPayTable, updateSelectedRowsInvoice } from '../multiBlPayment/slices/multiBlPaymentSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faFileInvoice, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import axiosInstance from '../../../axiosSetup';
import UploadIndicator from '../../../components/common/UploadIndicator';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee';

const Payment = () => {
  const { blNum } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const [isUnderlined, setIsUnderlined] = useState(false);
  const [loadingFileState, setLoadingFileState] = useState<any>();

  const paymentMode = useSelector(
    (state: RootState) => state.blPayment.paymentMode
  );

  console.log("paymentMode",paymentMode);

  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });
  const [fileName, setFileName] = useState('Upload File');
  const [serverFilePath, setServerFilePath] = useState<string | null>(null);
  const [uploadStatus, setuploadStatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [dropdownDownVisible, setdropdownDownVisible] = useState(false);


  // const [currentPage, setCurrentPage] = useState<number>(1);

  const currentPageInvoice = useSelector(
    (state: RootState) => state.dashboard.currentPageInvoice
  );
  const refresh = useSelector(
    (state: RootState) => state.dataTable.refreshDashboard
  );
  const data = useSelector(
    (state: RootState) => state.dashboard.invoiceDashboardList
  );
  const selectedRowsInvoice = useSelector(
    (state: RootState) => state.multiBlPayment.selectedRowsInvoice
  );

  const paymentStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showPayment
  );
  const releaseStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showRelease
  );
  const instructionStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showInstruction
  );
  const [instructionStatusNew, setInstructionStatusNew] = useState();

  const dataLoading = useSelector(
    (state: RootState) => state.payment.dataLoading
  );


  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const invoiceGrids = useSelector(
    (state: RootState) => state.dataTable.invoiceGrids
  );
  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: invoiceGrids?.[0]?.gridId,
  });
  const [currentState, setCurrentState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: invoiceGrids?.[0]?.gridId,
  });
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const selectedAmount = useSelector(
    (state: RootState) => state.multiBlPayment.selectedAmountAll
  );

  const payPalPopUp = useSelector(
    (state: RootState) => state.payment.paypalPop
  );

  

  const rowColumns = useSelector(
    (state: RootState) => state.instructionTable.rowColumns
  );
  var path = location.pathname;
  var parts = path.split('/');
  var blslider = parts[1];

  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageInvoiceDashboard(page));
  };
  const rbac: any = useSelector((state: RootState) => state.userProfile);

  const invoiceTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.invoiceTopSearchState
  );
  const loading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const sortState = useSelector(
    (state: RootState) => state.dataTable.sortState
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );
  const invDashboardName = useInvDashName()

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || '';
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);

  function useDebouncedEffect(effect: any, deps: any, delay: any) {
    const callback = useRef<any>();

    useEffect(() => {
      callback.current = effect;
    }, [effect]);

    useEffect(() => {
      const handler = () => {
        if (callback.current) {
          callback.current();
        }
      };

      const timer = setTimeout(handler, delay);

      return () => clearTimeout(timer);
    }, [...deps, delay]);
  }
  const handleUpload = async (e: any) => {
    setLoadingFileState(null);
    setFileName('Upload File');
    setSelectedFile(null);
    setuploadStatus(false);
    e.target.value = null;
    try {
      const response = await axiosInstance.post(
        `api/edo-rest-v2/v2/payment/uploadFile?filePath=${serverFilePath}&linerId=${linerId}`
      );

      if (response.status === 200) {
        //   const excelBlob = new Blob([response.data], {
        //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        //   });

        //   const url = window.URL.createObjectURL(excelBlob);

        //   const link = document.createElement('a');
        //   link.href = url;
        //   link.download =
        //     'Bulk payment upload- Bank transfer - Response file.xlsx';

        //   document.body.appendChild(link);
        //   link.click();
        //   document.body.removeChild(link);

        toast.info(response.data, {
          theme: "dark",
          autoClose: false,
        });
        setTimeout(() => {
          navigate('/payment');

        }, 3000);
      } else {
        toast.error(response?.data ?? 'Error uploading file');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Error uploading file');
    }
  };
  const generateColumnConfig = useGenerateColumnConfig()

  useEffect(() => {
    const invoiceDashboardDefinition =
      invoiceGrids?.[0]?.columnConfigGrid || '';

    if (!invoiceDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(invoiceDashboardDefinition)),
        getParams,
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [invoiceGrids, location]);

  useEffect(() => {
    if (
      selectedAmount.length === 0 &&
      location.pathname.split('/')[0] === 'payment'
    ) {
      navigate(`/payment${location.search}`);
    }
  }, [selectedAmount]);

  useEffect(() => {
    dispatch(updateDashbaordName(invDashboardName));
    //setPayCargoPop(true)
  }, []);
  useEffect(() => {
    if (refresh) loadPaymentDashboard(false);
    // server sometimes takes time to update the view so the delay
    setTimeout(() => dispatch(updateDashbaordRefresh(false)), 2000);
  }, [refresh]);

  const filterStateLocal = filterState?.['paymentOne'];
  const sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(',')
    ?.join?.(' ');

  useEffect(() => {
    if (
      location?.pathname &&
      dataCountry &&
      linerId
    ) {
      setTimeout(() => {
        dispatch(
          getColumnViews({
            gridName: 'INV_DASHBOARD',
            country: dataCountry,
            linerId: linerId,
          })
        );
      }, 150);
    }
    dispatch(updateParams({}));
  }, [dataCountry, linerId]);

  useEffect(() => {
    let selections: any = {};
    data.result?.forEach((d, i) => {
      if (
        selectedRowsInvoice.includes(d.invid) &&
        !d?.paymentRefNo &&
        !(d?.paidIndicator == '1')
      )
        selections[i] = true;
    });

    dispatch(updateSelectedBlListMultiPayTable(selections));
  }, [selectedRowsInvoice, data]);

  useEffect(() => {
    if (location.pathname.split('/')[1] === 'blsliderinvoice') {
      setObldetails(true);
    } else if (location.pathname === '/payment') {
      setObldetails(false);
    }
  }, [location.pathname, dataCountry, invoiceGrids]);

  useEffect(() => {
    const currentState = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: invoiceGrids?.[0]?.gridId,
    };
    if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
      setCurrentState({
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: invoiceGrids?.[0]?.gridId,
      });
    }
  }, [filterStateLocal, location.search, invoiceGrids]);

  // useEffect(() => {
  //   if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
  //     loadPaymentDashboard(true);
  //     setPreviousState(currentState);
  //   }
  // }, [currentState]);

  useDebouncedEffect(
    () => {
      if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
        loadPaymentDashboard(true);
        setPreviousState(currentState);
      }
    },
    [currentState],
    700
  );

  useEffect(() => {
    loadPaymentDashboard(false);
  }, [currentPageInvoice, linerId]);

  const loadPaymentDashboard = (filterUpdated: boolean) => {
    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any =
            selectedTopSearchFilter?.id && searchInputValue.trim() != ''
              ? { [selectedTopSearchFilter?.id]: [searchInputValue.trim()] }
              : {};

          if (invoiceGrids[0]) {
            let timeFlag = '';
            let timeDiff = 0;
            let dateRange: any = {};

            const newdata = invoiceGrids[0].configGroup?.find(
              (c: any) => c.userGridId == selectedConfigId?.['paymentOne']
            )?.columnConfigGrid;
            if (newdata) {
              timeFlag = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.filterState;
              timeDiff = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.timeDiff;
              dateRange = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.dateRange;
            }
            if (filterApiState.invdt && timeFlag == 'day' && dateRange) {
              filterApiState.invdt[1] = moment()
                .subtract(Math.abs(dateRange.to), 'days')
                .endOf('day')
                .toISOString();
              // const newDate = new Date(
              //   new Date(currentDate.toISOString()).getTime() -
              //     timeDiff * 24 * 60 * 60 * 1000
              // );
              filterApiState.invdt[0] = moment()
                .subtract(Math.abs(dateRange.from), 'days')
                .startOf('day')
                .toISOString();
            }
          }

          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }
          if (
            (profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              invoiceGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === 'liner'
          ) {
            setTimeout(() => {
              dispatch(
                getDashboardData({
                  v2: true,
                  gridName: invDashboardName,
                  payload: {
                    fields: /*  SelectedViewFields?.fields || */[],
                    filters:
                      selectedTopSearchFilter?.where == ''
                        ? finalFilterApiState
                        : {},

                    size: 20,
                    sorts:
                      sortState &&
                        sortState?.key != null &&
                        sortState?.value != null
                        ? [`${sortState?.key} ${sortState?.value}`]
                        : sort
                          ? [sort]
                          : ['invid desc'],
                    // page: currentPageInvoice,
                    page: filterUpdated ? 1 : currentPageInvoice,
                  },
                  dataCountry: dataCountry,
                  partnerId:
                    profileDetails.partnerType === 'customer'
                      ? profileDetails.partnerId
                      : null,
                  where: invoiceGrids?.[0]?.whereClause
                    ? AESEncrypt(invoiceGrids?.[0]?.whereClause)
                    : null,
                  parameter:
                    selectedTopSearchFilter?.where !== ''
                      ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                      : {},
                })
              );
            }, 250);
          }
        }
      } else {
        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            invoiceGrids?.[0]?.whereClause) ||
          profileDetails?.partnerType === 'liner'
        ) {
          setTimeout(() => {
            dispatch(
              getDashboardData({
                v2: true,
                gridName: invDashboardName,
                payload: {
                  fields: /* SelectedViewFields?.fields || */[],
                  filters:
                    selectedTopSearchFilter?.where === ''
                      ? invoiceTopSearchState
                      : {},
                  size: 20,
                  sorts:
                    sortState &&
                      sortState?.key != null &&
                      sortState?.value != null
                      ? [`${sortState?.key} ${sortState?.value}`]
                      : sort
                        ? [sort]
                        : ['invid desc'],
                  // page: currentPageInvoice,
                  page: filterUpdated ? 1 : currentPageInvoice,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === 'customer'
                    ? profileDetails.partnerId
                    : null,
                where: invoiceGrids?.[0]?.whereClause
                  ? AESEncrypt(invoiceGrids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ''
                    ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                    : {},
              })
            );
          }, 250);
        }
      }
    } else {
      if (
        !location.pathname.includes('blsliderinvoice/') &&
        location.pathname.includes('/payment') &&
        location?.state?.from == undefined
      ) {
        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            invoiceGrids?.[0]?.whereClause) ||
          profileDetails.partnerType === 'liner'
        ) {
          setTimeout(() => {
            dispatch(
              getDashboardData({
                v2: true,
                gridName: invDashboardName,
                payload: {
                  fields: /* SelectedViewFields?.fields || */[],
                  filters:
                    selectedTopSearchFilter?.where == ''
                      ? invoiceTopSearchState
                      : {},
                  size: 20,
                  sorts:
                    sortState &&
                      sortState?.key != null &&
                      sortState?.value != null
                      ? [`${sortState?.key} ${sortState?.value}`]
                      : sort
                        ? [sort]
                        : ['invid desc'],
                  //page: currentPageInvoice,
                  page: filterUpdated ? 1 : currentPageInvoice,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === 'customer'
                    ? profileDetails.partnerId
                    : null,
                where: invoiceGrids?.[0]?.whereClause
                  ? AESEncrypt(invoiceGrids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ''
                    ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                    : {},
              })
            );
          }, 250);
        }
      }
    }
  };

  const { t } = useTranslation();
  const [obldetails, setObldetails] = useState(false);
  const selectionDisabledForLiner =
    profileDetails.partnerType?.toLowerCase() === 'liner' ? false : true;
  const showColumnSettings = useSelector(
    (state: RootState) => state.payment.showColumnSettings
  );

  const onHideColumnSettings = () => {
    dispatch(toggleColumnSettingsPaymentList(false));
  };

  const selectionInitialState = useSelector(
    (state: RootState) => state.multiBlPayment.selectedRowsMultiPayTable
  );

  const handleFileChange = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

    if (!file.name.endsWith('.xlsx')) {
        toast.error('Please select a valid XLSX file.');
        event.target.value = null;
        return;
    }
      
    if (file.size > 10 * 1024 * 1024) {
          toast.error(
            'File size is greater than 10MB. Please upload file below 10MB'
          );
          event.target.value = null;
          return;
    } 

    if(file.name.length > 60){
        toast.error("File name should not exceed 60 characters");
        event.target.value = ""
        return
    }

          if (event.target.files) {
            const file = event?.target?.files[0];
            if (file) {
              setFileName(file.name);
            }
          }

          const formData = new FormData();
          formData.append('file', file);

          try {
            setLoadingFileState(0);

            axiosInstance
              .post('/api/edo-rest-v1/v1/view/file', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                onUploadProgress(progressEvent: any) {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
                  );
                  setLoadingFileState(percentCompleted);
                  // setLoadingFileState((state: any) => {
                  //   return { ...state, [fileDocId]: percentCompleted };
                  // });
                },
              })
              .then((response: any) => {
                setLoadingFileState(100);
                dispatch(showNotificationModal({
                  message: 'Click on "Allow Notifications" to recieve updates when your bulk file is processed.',
                  messageDenied: "To recieve bulk payment file notifications, please unblock notifications in browser preferences."
                }))
                setServerFilePath(response?.data?.path?.substring(response?.data?.path?.indexOf('/') + 1));
                // setLoadingFileState((state: any) => {
                //   return { ...state, [fileDocId]: 100 };
                // });
                // dispatch(
                //   updateSelectedFileServerPath({
                //     ...filePaths,
                //     [fileDocId]: [response.data.path, response.data.tempFileName],
                //   })
                // );
              })
              .catch(() => {
                setLoadingFileState(-1);
                // setLoadingFileState((state: any) => {
                //   return { ...state, [fileDocId]: -1 };
                // });
              });
            event.target.value = null;
            // add server path to redux
          } catch (error) {
            console.error('File Upload Error: ', error);
            console.error('File Upload Error: ', error);
          }
        
      
    }
  };

  const charLimit = (name: string, limit: number) =>
    name && name?.length >= limit ? name?.substring(0, limit) + '...' : name;

  const _onSelect = (data: any) => {
    // dispatch(updateSelectedBlListMultiPay([...selectedBlData,data.list]));
  };
  const getParams = () => {
    return {
      blid: blNum ? AESDecrypt(blNum) : '',
      pmtmode: blNum ? AESDecrypt(blNum) : '',
    };
  };
  const includesPropertyValue = (array: any, propertyName: any, value: any) => {
    return array.some(
      (item: { [x: string]: any }) => item[propertyName] === value
    );
  };
  const filteredselectedAmount = selectedAmount.filter(
    (item: { invcurr: any; id: any; localamount: any }) =>
      item.invcurr === selectedAmount[0]?.invcurr &&
      (item?.id?.substring(0, 2) == 'CD') ==
      (selectedAmount[0]?.id.substring(0, 2) == 'CD') &&
      isNaN(item.localamount) == isNaN(selectedAmount[0].localamount)
  );
  const totalAmount: any = filteredselectedAmount.reduce(
    (accumulator: any, currentObj: any) => {
      const amountAsNumber: number = parseFloat(currentObj.amount);
      if (!isNaN(amountAsNumber)) {
        return accumulator + amountAsNumber;
      }
      return accumulator;
    },
    0
  );

  const linkStyle = {
    cursor: 'pointer',
    fontSize: '13px',
    fontStyle: 'italic',
    textDecoration: isUnderlined ? 'underline' : 'none',
  };

  const paidUnpaidFilter =
    filteredselectedAmount.some(
      (obj: { amount: string }) => obj.amount === '0'
    ) &&
    filteredselectedAmount.some(
      (obj: { amount: string }) => obj.amount !== '0'
    );


  const FilterViewChild = (() => {
    return (
      <>
        {
          rbac.rbacDetails.createUpdatePayment == 'Y' &&
          <>
            <Button
              variant='falcon-primary'
              onClick={() => navigate(`/payment/new${location.search}`)}
              disabled={
                rbac.rbacDetails.createUpdateCredit != 'Y'
                  ? (includesPropertyValue(
                    filteredselectedAmount,
                    'amount',
                    '0'
                  ) ||
                    filteredselectedAmount.length == 0 ||
                    totalAmount.toFixed(2) <= 0) &&
                  paidUnpaidFilter == false
                  : false
              }
              size='sm'
              style={{
                marginRight: '0.5rem',
                position: 'relative',
                float: 'left',
              }}
            >
              <div className='d-flex'>
                <FontAwesomeIcon icon={faCreditCard} className='me-2 mt-1 ' />
                <span>Pay</span>
              </div>
            </Button>
            {
              rbac.rbacDetails.bulkPymtCUD === 'Y' &&
              <Dropdown
                navbar={true}
                style={{
                  marginRight: '0.5rem',
                  position: 'relative',
                  float: 'left',
                }}
                onClick={() => setdropdownDownVisible(true)}
              >
                {profileDetails?.partnerType === 'customer' ? (
                  <Dropdown.Toggle bsPrefix='toggle' as={Link} to='' className='nav-link'>
                    <div>
                      <Button
                        variant='ms-3 btn btn-falcon-primary z-inxe-agent btn-sm'
                        size='sm'
                        className='d-flex align-items-center'
                        title='Bulk Upload'
                        onClick={() => {
                          setFileName('Upload File');
                          setSelectedFile(null);
                          setuploadStatus(false);
                          setLoadingFileState(null);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{
                            fontSize: '14px',
                            color: '#f5822a',
                            marginRight: '0.5rem',
                          }}
                          title='Bulk Payment'
                        />
                        <span>Bulk</span>
                      </Button>

                      {/*<Avatar
                  src={
                    profileDetails?.partnerType === 'liner'
                      ? LinerLogo
                      : faFileDownload
                  }
                  className='ms-2'
                  size='xl'
              /> */}
                    </div>
                  </Dropdown.Toggle>
                ) : (
                  ''
                )}

                {dropdownDownVisible && (
                  <Dropdown.Menu
                    className='dropdown-caret dropdown-menu-card  dropdown-menu-end'
                    style={{ height: '90px' }}
                  >
                    {
                      <div>
                        <div className='row g-2 align-items-sm-center'>
                          <div className='col'>
                            <div className='row align-items-center'>
                              <div className='col '>
                                <span
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                  className='fs-0  text-primary'
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <label
                                      className='btn btn-sm btn-falcon-primary'
                                      style={{
                                        marginBottom: '0rem',
                                        margin: '0.5rem',
                                      }}
                                      htmlFor='fileInput'
                                    >
                                      <FontAwesomeIcon
                                        icon={faUpload}
                                        className='me-2 '
                                        style={{
                                          fontSize: '16px !important',
                                          color: '#f5822a',
                                          marginLeft: '0.5rem',
                                          cursor: 'pointer',
                                        }}
                                        title='Upload'
                                      />
                                    </label>
                                    <input
                                      type='file'
                                      id='fileInput'
                                      accept='.xlsx'
                                      onClick={(event: any) => {
                                        event.target.value = '';
                                        setFileName('Upload File');
                                        setSelectedFile(null);
                                        setuploadStatus(false);
                                        setLoadingFileState(null);
                                      }}
                                      onChange={handleFileChange}
                                      style={{ display: 'none' }}
                                    />
                                    <>
                                      <span
                                        style={{
                                          fontSize: '14px',
                                          color: '#2c7be5',
                                          fontWeight: '600',
                                        }}
                                      >
                                        {charLimit(fileName, 15)}
                                      </span>

                                      {loadingFileState != null && (
                                        <UploadIndicator
                                          style={{ marginLeft: '10px' }}
                                          progress={loadingFileState}
                                          fail={loadingFileState === -1}
                                        />
                                      )}
                                    </>
                                  </div>

                                  {loadingFileState == 100 && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Button
                                        onClick={handleUpload}
                                        className={`me-2 ${loadingFileState === 100 ? '' : 'd-none'
                                          }`}
                                        variant='falcon-primary'
                                        size='sm'
                                        style={{ margin: '0.5rem' }}
                                        type='submit'
                                      >
                                        {t('resource_1:submit')}
                                      </Button>
                                    </div>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Dropdown.Divider />
                        <div
                          style={{ width: '23rem' }}
                          className='row g-2 align-items-sm-center'
                        >
                          <div
                            className='col'
                            style={{ marginTop: '0.5rem', marginLeft: '2.0rem' }}
                          >
                            <div className='row align-items-center'>
                              <div className='col '>
                                <span
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                  className='fs-0  text-primary'
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {/* <label onClick={exportDataAsCSV} className='btn btn-sm btn-falcon-primary' style={{marginBottom:'0rem',margin:'0.5rem'}}>
                                    <FontAwesomeIcon
                                      icon={faDownload}
                                      className='me-2 '
                                      style={{
                                        fontSize: '10px !important',
                                        color: '#f5822a',
                                        marginLeft:'0.5rem',
                                        
                                      }}
                                      title='Download'
                                    />
                                    </label> */}
                                    <span
                                      onClick={() => {
                                        window.open(
                                          '/xlsx/BulkPaymentUploadBankTransferTemplate.xlsx'
                                        );
                                      }}
                                      onMouseEnter={() => setIsUnderlined(true)}
                                      onMouseLeave={() => setIsUnderlined(false)}
                                      style={{ ...linkStyle, marginTop: '5px' }}
                                    >
                                      Click here to download the sample excel format
                                    </span>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </Dropdown.Menu>
                )}
              </Dropdown>
            }
            {rbac.rbacDetails.standaloneInvPymtCUD === 'Y' && (
              <Button
                variant='falcon-primary'
                onClick={() => navigate(`/payment/addInvoice${location.search}`)}
                className='d-flex align-items-center'
                size='sm'
                style={{
                  marginRight: '0.5rem',
                  position: 'relative',
                  float: 'left',
                  width: 'max-content',
                }}
              >
                <div className='d-flex'>
                  <FontAwesomeIcon icon={faPlus} className='me-2 mt-1 ' />
                  <span>{t('resource_2:addInvoice')}</span>
                </div>
              </Button>
            )}
            {rbac.rbacDetails.getInvoiceCUD === 'Y' &&
              (
                <Button
                  variant='falcon-primary'
                  onClick={() => navigate(`/payment/getInvoiceRequest${location.search}`)}
                  className='d-flex align-items-center'
                  size='sm'
                  style={{
                    marginRight: '0.5rem',
                    position: 'relative',
                    float: 'left',
                    width: 'max-content',
                  }}
                >
                  <div className='d-flex'>
                    <FontAwesomeIcon icon={faFileInvoice} style={{ color: '#F5822A' }} className='me-2 mt-1 ' />
                    <span>{t('resource_2:getInvoice')}</span>
                  </div>
                </Button>
              )
            }
          </>
        }
      </>
    )
  })()

  useEffect(() => {
    // if (location?.pathname) {
    //   dispatch(
    //     getColumnViews({ gridName: 'INV_DASHBOARD', country: dataCountry })
    //   );
    // }
    dispatch(updateParams({}));
    // dispatch(
    //   updateSortStateDataTable({
    //     key: null,
    //   })
    // );
  }, [dataCountry]);

  useEffect(() => {
    let selections: any = {};
    data.result?.forEach((d, i) => {
      if (
        selectedRowsInvoice.includes(d.invid) &&
        !d?.paymentRefNo &&
        !(d?.paidIndicator == '1')
      )
        selections[i] = true;
    });

    dispatch(updateSelectedBlListMultiPayTable(selections));
  }, [selectedRowsInvoice, data]);


  useEffect(() => {
    if (instructionStatus !== null) {
      setInstructionStatusNew(instructionStatus.trim());
    }
  }, [instructionStatus]);

  const sideWidth = useMemo(
    () =>
      Object.entries({
        [`/payment`]: 0,
        [`/blsliderpayment/:blNum`]: 6,
        [`/payment/addInvoice/invoicePayment`]: 6,
        [`/payment/new`]: 6,
        [`/payment/getInvoiceRequest`]: 6,
        [`/payment/addInvoice`]: 6,
        [`/payment/bl/:blNum/unpaid`]: 6,
        [`/payment/bl/:blNum/unpaid/payment/:pmtid`]: 6,
        [`/payment/bl/:blNum/unpaid/payment`]: 6,
      }).find(([path, _]) => matchPath(path, location.pathname))?.[1] ?? 9,
    [location.pathname]
  );

  const docHistory = location.pathname.includes('payment/bl/');
  const standalone = location.pathname.includes('addInvoice/invoicePayment');

 

  return (
    <>
      <DashboardContainer
        sideWidth={sideWidth}
        inner={docHistory || standalone}
        innerOffset={!standalone ? 50 : 0}
        middleScroll={
          !docHistory &&
          (['/payment/new', '/payment/addInvoice', '/payment/getInvoiceRequest'].includes(location.pathname) ||
            !matchPath('/payment/:blNum', location.pathname))
        }
        middleChildren={
          blslider == 'blsliderinvoice' ? (
            <OblDetails
              blslider={`/blsliderinvoice/${location.pathname.split('/')[1]}`}
            />
          )
            : docHistory ||
              !(['/payment/new', '/payment/addInvoice', '/payment/getInvoiceRequest'].includes(location.pathname) ||
                !matchPath('/payment/:blNum', location.pathname)) ?
              <div className={`flex-1 h-100 smoothDiv w-${standalone ? 50 : 100}`}>
                <Header
                  backNavRoute={'/payment'}
                  paymentStatus={paymentStatus}
                  instructionStatus={instructionStatusNew}
                  releaseStatus={releaseStatus}
                />
                <DocumentHistory />
              </div>
              : (
                <div className={`flex-1 h-100 smoothDiv w-${standalone ? 50 : 100}`}>
                  {
                    location.pathname.includes('getInvoiceRequest') ? (
                      <GetInvoiceRequest />
                    )
                      :
                      location.pathname.includes('addInvoice') ? (
                        <StandaloneInvoicePayment />
                      ) :
                        (
                          <Outlet />
                        )
                  }
                </div>
              )
        }
      >
        <FilterView
          loadList={loadPaymentDashboard}
          showPayButton={rbac.rbacDetails.createUpdatePayment == 'Y' && !!matchPath('/payment', location.pathname)}
          showUploadButton
          showAmount={
            location.pathname.includes('/payment') &&
            (location.pathname.includes('addInvoice') ||
              location.pathname.includes('new') ||
              !matchPath('/payment/:blNum', location.pathname))
          }
          showExport={
            matchPath('/blsliderpayment/:blNum', location.pathname) ? false : true
          }
          showColumnSettings={
            matchPath('/blsliderpayment/:blNum', location.pathname) ? false : true
          }
          dataLoading={dataLoading}
          toggleColumnSettings={toggleColumnSettingsPaymentList}
          columnSettingsPersistKey='@odex/payment/v1'
          uniqueFilterKey='paymentOne'
          configViews={
            //  grids?.find?.((g) => g?.gridId === 2)?.configGroup || []
            invoiceGrids?.find?.((g) => g?.gridName === 'INV_DASHBOARD')
              ?.configGroup || []
          }
          showRightDiv={
            location.pathname === '/payment/new' ||
              location.pathname === '/payment/addInvoice' ||
              location.pathname === '/payment/getInvoiceRequest' ||
              location.pathname === `/payment/${encodeURIComponent(blNum || '')}` ||
              obldetails
              ? false
              : true && matchPath('/blsliderpayment/:blNum', location.pathname)
                ? false
                : true
          }
          child={FilterViewChild}
          dashboardName='INV_DASHBOARD'
          data={data}
          gridDef={invoiceGrids}
        />
        {columns?.length > 0 && (
          <DataTable
            data={data?.result || null}
            columns={columns}
            dataCountry={dataCountry || ''}
            sortable
            searchable={false}
            showColumnFilter={false}
            selection={selectionDisabledForLiner}
            perPage={20}
            pagination
            numberPagination
            maxPage={parseInt(data?.maxPage || '100')}
            hasNext={data?.hasNext}
            currentPage={currentPageInvoice}
            gridId={invoiceGrids?.[0]?.gridId || 0}
            onChangePageNumber={(pageNumber) => setCurrentPage(pageNumber)}
            showExport={false}
            showColumnSettings={showColumnSettings}
            onHide={onHideColumnSettings}
            onCustomSelectChange={_onSelect}
            selectionInitialState={selectionInitialState}
            disableRowSelection={{
              accessor: 'payment',
              value: 'Paid',
            }}
            onSelectRow={(dat) => {
              if (dat?.action === 'add') {
                if (dat?.data?.length > 0) {
                  dispatch(
                    updateSelectedRowsInvoice([
                      ...selectedRowsInvoice,
                      ...dat?.data?.map((d: any) => d.invid),
                    ])
                  );
                } else {
                  dispatch(
                    updateSelectedRowsInvoice([
                      ...selectedRowsInvoice,
                      dat?.data?.invid,
                    ])
                  );
                }
              } else {
                if (dat?.data?.length > 0) {
                  dispatch(
                    updateSelectedRowsInvoice(
                      selectedRowsInvoice.filter(
                        (f) => !dat?.data?.map((v: any) => v?.invid).includes(f)
                      )
                    )
                  );
                } else {
                  dispatch(
                    updateSelectedRowsInvoice(
                      selectedRowsInvoice.filter((f) => f !== dat?.data?.invid)
                    )
                  );
                }
              }
            }}
            columnSettingsPersistKey='@odex/payment/v1'
            customSort={true}
            uniqueFilterKey='paymentOne'
            clearSelection={() => {
              dispatch(updateSelectedBlListMultiPayTable({}));
            }}
            staticHiddenCols={configs?.staticHiddenCols || []}
            defaultHiddenCols={
              configs?.defaultHiddenCols?.filter((v) => v !== 'id') || []
            }
            tableMinHeight={400}
            gridName='INV_DASHBOARD'
            dataLoading={false}
            configGroup={invoiceGrids?.[0]?.configGroup || []}
          />
        )}
      </DashboardContainer>
      {dataCountry == 'US' && profileDetails?.partnerType === 'customer' ?
        <>
        <Modal show={payPalPopUp} size='lg' className='payPalModal'>
          <img src={modalImage} style={{ width: '600px' }} className='slide'  ></img>
          <button
            className="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
            onClick={() =>
              dispatch(setPayPalPopUp(false))
            }
          >
          </button>
        </Modal>
        </>
        : ""}

     
      </>
  );
};

export default Payment;
