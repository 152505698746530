import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";

export default function VideoPopup({show, setShow}:any){

    const videoRef = useRef<any>(null);

    useEffect(()=>{
            setTimeout(
                ()=>{
                    show?videoRef.current?.play?.():videoRef.current?.stop?.()
                    
                },2000
            );
            console.log(videoRef.current,"element")
    },[show, videoRef.current])
    return <Modal show={show} size={"lg"} onHide={()=>{setShow(false)}} className="payPalModal">
        <video style={{height:"90vh"}} autoPlay={true} loop={true} ref={videoRef} disablePictureInPicture>
    <source src={`${process.env?.REACT_APP_API_GATEWAY_HOST}/paypal1.mp4`} type="video/mp4"/>
        </video>
    </Modal>
}