import { createSlice } from "@reduxjs/toolkit";

import { getInvoiceRequest } from "../actions/getInvoiceRequest";

interface Invoices {
    invoice_number:string,
    invoice_amount:string,
    invoice_type:string
}

interface GetInvoiceSlice{
    data:Invoices[]
}

const initialState: GetInvoiceSlice = {
    data:[{
    invoice_number:'',
    invoice_amount:'',
    invoice_type:''    
}]
};
  
export const getInvoiceSlice = createSlice({
    name: 'getInvoiceSlice',
    initialState,
    reducers: {
        clearGetInvoiceList:(state)=>{
            state.data=[]
        }
    },
    extraReducers: (builder) => {
      builder.addCase(getInvoiceRequest.fulfilled, (state, action)=>{
          state.data=action.payload
      })
      builder.addCase(getInvoiceRequest.rejected, (state, action)=>{
        state.data=[]
    })
  }}); 

  export const { clearGetInvoiceList } = getInvoiceSlice.actions;
export default getInvoiceSlice.reducer;
