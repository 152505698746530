import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const getUsernameFromMetaTag = () => {
  const querySelector = document.querySelector("meta[name='user']");
  return querySelector ? querySelector.getAttribute('content') : null;
};

export interface INavbarTop {
  searchInputValue: string;
  searchType: string;
  blTopSearchState: any;
  invoiceTopSearchState: any;
  instructionTopSearchState: any;
  userTopSearchState: any;
  standingInstSearchState: any;
  selectedTopSearchFilter: any;
  paymentFundTopSearch: any;
  paymentTopSearchState: any;
  receiptTopSearchState: any;
  gateInGateOutTopSearchSate:any
  emailTopSearchState: any;
  mappingTopSearchState: any;
  reportTopSearchState: any;
  selectedDashbaordName: string;
  prevSearchInput: string;
  userName: any;
  linerId: string;
  notificationModal: {
    show: boolean,
    message: string,
    buttons: boolean
  }
}

const initialState: INavbarTop = {
  searchInputValue: '',
  searchType: '1',
  blTopSearchState: {},
  invoiceTopSearchState: {},
  instructionTopSearchState: {},
  userTopSearchState: {},
  paymentTopSearchState: {},
  receiptTopSearchState: {},
  gateInGateOutTopSearchSate:{},
  emailTopSearchState: {},
  standingInstSearchState: {},
  paymentFundTopSearch: {},
  mappingTopSearchState: {},
  reportTopSearchState: {},
  selectedTopSearchFilter: {},
  selectedDashbaordName: '',
  prevSearchInput: '',
  userName: getUsernameFromMetaTag(),
  linerId: '',
  notificationModal: {
    show: false,
    buttons: true,
    message: ""
  }
};

export const navbarTopSlice = createSlice({
  name: 'navbarTop',
  initialState,
  reducers: {
    updateSearchInputValueNT: (state, action: PayloadAction<string>) => {
      state.searchInputValue = action.payload;
    },
    updateTopSearchFilter: (state, action: PayloadAction<object>) => {
      state.selectedTopSearchFilter = action.payload;
    },
    updateDashbaordName: (state, action: PayloadAction<any>) => {
      state.selectedDashbaordName = action.payload;
    },
    updateBlTopSearchState: (state, action: PayloadAction<any>) => {
      state.blTopSearchState = action.payload;
    },
    updateInvoiceTopSearchState: (state, action: PayloadAction<any>) => {
      state.invoiceTopSearchState = action.payload;
    },
    updatePaymentTopSearchState: (state, action: PayloadAction<any>) => {
      state.paymentTopSearchState = action.payload;
    },
    updateReceiptTopSearchState: (state, action: PayloadAction<any>) => {
      state.receiptTopSearchState = action.payload;
    },
    updateGateInGateOutTopSearchSate:(state,action: PayloadAction<any> )=>{
      state.gateInGateOutTopSearchSate = action.payload
    },
    updateEmailTopSearchState: (state, action: PayloadAction<any>) => {
      state.emailTopSearchState = action.payload;
    },
    updateMappingTopSearchState: (state, action: PayloadAction<any>) => {
      state.mappingTopSearchState = action.payload;
    },
    updateReportTopSearchState: (state, action: PayloadAction<any>) => {
      state.reportTopSearchState = action.payload;
    },
    updateInstructionTopSearchState: (state, action: PayloadAction<any>) => {
      state.instructionTopSearchState = action.payload;
    },
    updatePaymentFundTopSearch: (state, action: PayloadAction<any>) => {
      state.paymentFundTopSearch = action.payload;
    },
    updateUserTopSearchState: (state, action: PayloadAction<any>) => {
      state.userTopSearchState = action.payload;
    },
    setSearchType: (state, action: PayloadAction<string>) => {
      state.searchType = action.payload;
    },

    updatePrevSearchInput: (state, action: PayloadAction<string>) => {
      state.prevSearchInput = action.payload;
    },
    updateLinerId: (state, action: PayloadAction<any>) => {
      state.linerId = action.payload;
    },
    hideNotificationModal: (state) => {
      state.notificationModal.show = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(showNotificationModal.fulfilled, (state, action) => {
      if (action.payload) state.notificationModal = action.payload
    })
  }
});

export const showNotificationModal = createAsyncThunk(
  "notification/showNotificationModal",
  ({ messageDenied, message = "Allow us to send you notifications", buttons = true, force = false , skipWait = false }:
     { message?: string, buttons?: boolean, force?: boolean, messageDenied?:string, skipWait?:boolean }) => {
    
    // check cooldown
    const notificationModalFlag = localStorage.getItem('notification_modal_flag') 
    const showModal = !notificationModalFlag || new Date() >= new Date(notificationModalFlag) || skipWait
    
    if(!showModal) return null
    
    const modalCooldownDays = 3
    const coolDownDate = new Date()
    coolDownDate.setHours(coolDownDate.getHours()+ modalCooldownDays*24)
    localStorage.setItem('notification_modal_flag',(coolDownDate).toISOString())

    if (Notification.permission == 'default') {
      return {
        show: true,
        message,
        buttons
      }
    }
    if (Notification.permission == 'denied' && force) {
      return {
        show: true,
        message: messageDenied??message,
        buttons: false
      }
    }
  }
)

export const {
  updateSearchInputValueNT,
  updateTopSearchFilter,
  setSearchType,
  updateBlTopSearchState,
  updateInvoiceTopSearchState,
  updateDashbaordName,
  updatePrevSearchInput,
  updateInstructionTopSearchState,
  updatePaymentFundTopSearch,
  updateUserTopSearchState,
  updateLinerId,
  updatePaymentTopSearchState,
  updateReceiptTopSearchState,
  updateGateInGateOutTopSearchSate,
  updateEmailTopSearchState,
  updateReportTopSearchState,
  updateMappingTopSearchState,
  hideNotificationModal
} = navbarTopSlice.actions;

export default navbarTopSlice.reducer;
