import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../components/common/Flex';
import SoftBadge from '../../../components/common/SoftBadge';
import {
  faClipboard,
  faFileInvoice,
  faCreditCard,
  faMoneyCheckAlt,
  faLineChart,
  faReceipt,
  faEnvelope,
  faArrowRightArrowLeft,
} from '@fortawesome/free-solid-svg-icons';

const NavbarVerticalMenuItem = ({ route }) => {
  const getIcon = (icon) => {
    switch (icon) {
      case 'clipboard':
        return faClipboard;
      case 'file-invoice':
        return faFileInvoice;
      case 'credit-card':
        return faCreditCard;
      case 'pay':
        return faMoneyCheckAlt;
      case 'report':
        return faLineChart;
      case 'receipt':
        return faReceipt
      case 'emailDashboard':
        return faEnvelope
      case 'gateinout':
        return faArrowRightArrowLeft  
      default:
        return icon;
    }
  };

  return (
    <Flex alignItems='center'>
      {route.icon && (
        <span className='nav-link-icon'>
          <FontAwesomeIcon icon={getIcon(route.icon)} className='fa-list-ul'/>
        </span>
      )}
      <span className='nav-link-text ps-1'>{route.name}</span>
      {route.badge && (
        <SoftBadge pill bg={route.badge.type} className='ms-2'>
          {route.badge.text}
        </SoftBadge>
      )}
    </Flex>
  );
};

// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired,
};

export default React.memo(NavbarVerticalMenuItem);
